import React from "react";
import { useState, useEffect } from "react";
import { useInView } from "react-intersection-observer";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import carosel1 from "../assets/home_default/1.mp4";
import carosel2 from "../assets/home_default/2.mp4";
import carosel3 from "../assets/home_default/3.mp4";
import carosel4 from "../assets/home_default/4.mp4";
import carosel5 from "../assets/home_default/5.mp4";
import filtered_outlet from "../assets/homepage_filters/filtered_outlet.jpg";
import event_on_preference from "../assets/homepage_filters/event_on_preference.png";
import categorized_artists from "../assets/homepage_filters/categorized_artists.jpg";
import CircularProgress from "@mui/material/CircularProgress";
import Header from "./Header";
import Footer from "./Footer";
import { HomeBanner } from "./HomeBanner";
import "./HomePage.css";
import { axiosPrivate } from "../api/axios";
import CloseIcon from "@mui/icons-material/Close";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import DisabledByDefaultIcon from "@mui/icons-material/DisabledByDefault";
import { BASE_URL } from "../constants";

function Homepage() {
  const videoURLs = [carosel1, carosel2, carosel3, carosel4, carosel5];
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  const [showCookiePopUp, setShowCookiePopUp] = useState(false);
  const cookie = localStorage.getItem("cookie");
  const [upcomingEvents, setUpcomingEvents] = useState([]);
  const [previousEvents, setPreviousEvents] = useState([]);
  const [liveEvents, setLiveEvents] = useState([]);
  const [promotedEvents, setPromotedEvents] = useState([]);
  const [upcomingImages, setUpcomingImages] = useState([]);
  const [previousImages, setPreviousImages] = useState([]);
  const [liveImages, setLiveImages] = useState([]);
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  const [eventVideoIndex, seteventVideoIndex] = useState(0);

  const [ref, inView] = useInView({
    triggerOnce: true, // Animation triggers only once when the element comes into view
  });

  const settings = {
    infinite: true,
    slidesToShow: 8,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
  };

  useEffect(() => {
    let cookie = localStorage.getItem("cookie");

    if (cookie === "true") {
      setShowCookiePopUp(false);
    } else {
      setShowCookiePopUp(true);
    }

    const interval = setInterval(() => {
      setCurrentVideoIndex((prevIndex) => (prevIndex + 1) % videoURLs.length);
      seteventVideoIndex(
        (prevIndex) => (prevIndex + 1) % promotedEvents.length
      );
    }, 5000); // Change the interval (in milliseconds) as needed

    return () => clearInterval(interval);
  }, [promotedEvents]);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveSlideIndex(
        (prevIndex) => (prevIndex + 1) % upcomingImages.length
      );
    }, 3000); // Adjust the interval according to your preference in milliseconds

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, [upcomingImages.length]);

  useEffect(() => {
    let isMounted = true;

    const getEventsBycategory = async (event_category) => {
      const response = await axiosPrivate.get(`/events/${event_category}`);

      if (event_category === "upcoming_events") {
        setUpcomingEvents(response.data);
      } else if (event_category === "previous_events") {
        setPreviousEvents(response.data);
      } else {
        setLiveEvents(response.data);
      }
    };

    const getPromotedEvents = async () => {
      const response = await axiosPrivate.get(`/events/promoted`);

      setPromotedEvents(response.data);
    };

    isMounted && getEventsBycategory("upcoming_events");
    isMounted && getEventsBycategory("previous_events");
    isMounted && getEventsBycategory("live_events");

    isMounted && getPromotedEvents();

    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    if (upcomingEvents.length > 0) {
      const images = upcomingEvents?.map((event) => ({
        id: event.event_id,
        name: event.event_name,
        image: event.poster_url,
      }));

      setUpcomingImages(images);
    }
  }, [upcomingEvents]);

  useEffect(() => {
    if (previousEvents.length > 0) {
      const images = previousEvents?.map((event) => ({
        id: event.event_id,
        name: event.event_name,
        image: event.poster_url,
      }));

      setPreviousImages(images);
    }
  }, [previousEvents]);

  const handleCookieAccept = () => {
    localStorage.setItem("cookie", true);
    setShowCookiePopUp(false);
  };

  const handleCookieReject = () => {
    localStorage.setItem("cookie", false);
    setShowCookiePopUp(false);
  };

  useEffect(() => {
    if (liveEvents.length > 0) {
      const images = liveEvents?.map((event) => ({
        id: event.event_id,
        name: event.event_name,
        image: event.poster_url,
      }));

      setLiveImages(images);
    }
  }, [liveEvents]);

  return (
    <div className="">
      <div className="layout flex flex-column">


        {showCookiePopUp && (
          <div
            className=" px-3 py-3 div-cookies-alert rounded" >
            <div >
              <p className="text-light text-cookies-alert " >
                Cookies, Web Beacons, and Data Storage: Your Quick Guide Welcome
                to NEROLIFE : Thoughts Applied! We use technologies like cookies
                and web beacons to enhance your experience. Here's a brief
                overview: Cookies: We use cookies to improve functionality and
                personalize your experience. You can manage them in your browser
                settings. Web Beacons: These are small, transparent images that
                help us track user interactions and measure engagement. They
                work alongside cookies for better insights. Data Storage: We
                securely store data to maintain confidentiality. Your
                information is never sold, and you have control over your data
                as per our privacy policy. By using our site, you agree to these
                practices. For more details, check our Privacy Policy page. If
                you have questions, feel free to reach out. Thanks for being
                here!
              </p>
            </div>

            <div  className="flex  justify-content-end div-cookie-alert-btn ">
              <div
                className="flex  justify-content-evenly align-items-center mt-1 "
                style={{ width: "100px" }}
              >
                <div
                  onClick={handleCookieAccept}
                  className="text-light cursor-pointer"
                >
                  <TaskAltIcon />
                </div>
                <div
                  onClick={handleCookieReject}
                  className="text-light cursor-pointer"
                >
                  <DisabledByDefaultIcon />
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="homePageVideos">
          <div className="home-carosel">
            {videoURLs.map((url, index) => (
              <video
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
                key={index} autoPlay loop muted className={index === currentVideoIndex ? "visible" : "hidden"} >
                <source src={url} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            ))}
          </div>

          <div className="home-tags flex align-items-center ">
            <div style={{ width: "100%" }} className="">
              <h1 className="homeHeadings heading1"> EXPLORE NEW SPOTS, </h1>
              <h1 className="homeHeadings heading2" > ENJOY YOUR FAVOURITE ARTISTS,</h1>
              <h1 className="homeHeadings heading3">BOOK EVENTS AND DO MORE</h1>
            </div>
          </div>
        </div>


        <div className="firstwindow">
          <div className="  firstwindowleft">
            <h1 ref={ref} className={`mainheading animated-element ${inView ? "animate" : "" }`} >
              TOP SHOWS OF THE WEEK
            </h1>
          </div>

          <div className="  flex justify-content-end firstwindowright">
            {promotedEvents.map((event, index) => (
              <video
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
                key={index}
                autoPlay
                loop
                muted
                className={index === eventVideoIndex ? "visible" : "hidden"} >
                <source src={`${BASE_URL}${event.intro_video}`} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            ))}
          </div>
        </div>

        <div className="flex mt-5 flex-column mobile-top-shows ">
          <div className="  mb-2 flex  homeslider-card-img" >
            <HomeBanner
              // images={previousImages}
              images={upcomingImages}
              // speed={10000}
              width="300px"
              height="300px"
              type="event"
            />
          </div>
          <h1 className="mt-3 "
            style={{ fontSize: 18, color: "white", fontWeight: "500" }} >
            COMING SOON NEAR YOU
          </h1>
        </div>

        <div className="flex flex-column mobile-live-shows mt-5">
          <div className="  mb-2 flex  homeslider-card-img"  >
            <HomeBanner
              images={liveImages}
              // speed={10000}
              width="300px"
              height="300px"
              direction="right"
              type="event"
            />
          </div>


          <div className="flex align-items-center mb-4">
            <h1 className="mt-3"
              style={{ fontSize: 18, color: "white", fontWeight: "500" }} >
              LIVE NOW
            </h1>
            <CircularProgress
              className="ms-2 mt-3"
              size={20}
              sx={{ color: "green" }}
              thickness={4}
            />
          </div>
        </div>

        <div className="flex flex-column homeslider-card mt-5">
          <div
            className="  mb-2 flex homeslider-card-img" >
            <HomeBanner
              images={previousImages}
              // speed={20000}
              width="300px"
              height="300px"
              direction="left"
              type="event"
            />
          </div>
          <h1
            className="mt-3 mb-3"
            style={{ fontSize: 18, color: "white", fontWeight: "500" }} >
            MIGHT HAVE MISSED OUT ON
          </h1>
        </div>

        <div  className=" flex mt-5  justify-evenly align-items-center flex-wrap marginssss">
          <div className="flex flex-column entire-overlay--card " >
            <div className="overlay-trigger-container home-overlay-cards" >
              <img src={filtered_outlet}
                alt="filtered_outlet"
                className="overlay-trigger"
                style={{ height: "100%" }} />
              <div className="overlay flex align-items-center justify-center">
                <div className="overlay-content text-left">
                  <h1 style={{ fontSize: "15px", lineHeight: "20px" }}>
                    Welcome to Outlets Section, your key to an array of
                    captivating venues! Navigate effortlessly through our
                    curated categories, ensuring your ideal setting is just a
                    click away. Immerse yourself in the electric ambiance of our
                    Nightclubs, or unwind in the sophisticated charm of Lounges.
                    Experience culinary delights and cozy atmospheres in our
                    Resto Cafes, elevate your night at Rooftop Bars, revel in
                    the camaraderie of Pubs, or bask in the refreshing vibes of
                    Poolside venues. Open Air Venues provide a perfect al fresco
                    experience for Enthusiasts. Your perfect venue, tailored to
                    your desires, awaits discovery in our Outlets section!
                  </h1>
                </div>
              </div>
            </div>

            <div
              style={{ height: "10%" }}
              className="flex align-items-center justify-center"
            >
              <h1
                style={{
                  left: "50%",
                  color: "white",
                  fontWeight: "600",
                  fontSize: 17,
                }}
              >
                FILTERED OUTLETS
              </h1>
            </div>
          </div>

          <div
            className="flex flex-column entire-overlay--card"
          // style={{ height: "500px" }}
          >
            <div
              className="overlay-trigger-container home-overlay-cards"
            // style={{ height: "90%", width: "400px" }}
            >
              <img
                src={event_on_preference}
                alt="event_on_preference"
                className="overlay-trigger"
                style={{ height: "100%" }}
              />
              <div className="overlay flex align-items-center justify-center">
                <div className="overlay-content text-left">
                  <h1 style={{ fontSize: "15px", lineHeight: "20px" }}>
                    Welcome to Events Section, your passport to a diverse
                    musical universe! Tailor your experience with ease,
                    navigating through an array of genres that resonate with
                    your unique taste. Immerse yourself in the glitz of
                    Bollywood (हिंदी), the pulsating beats of EDM, and chart
                    topping Commercial hits, The atmospheric depths of
                    Underground Techno Music. Explore the serenity of Deep,
                    Chill & House Music, the cutting-edge Sounds & Bass of
                    Dubstep, and the urban Trap beats of Hip-Hop. Whether you
                    crave the epic vibes of Big Room & Electro, the Authenticity
                    of Live Bands, the Intensified Metals, or the Soulful
                    melodies of Acoustics, Our Events Section ensures that the
                    music take you to events which speak to your individual
                    taste.
                  </h1>
                </div>
              </div>
            </div>

            <div
              style={{ height: "10%" }}
              className="flex align-items-center justify-center"
            >
              <h1
                style={{
                  left: "50%",
                  color: "white",
                  fontWeight: "600",
                  fontSize: 17,
                }}
              >
                EVENTS ON PREFERENCE
              </h1>
            </div>
          </div>

          <div
            className="flex flex-column entire-overlay--card"
          // style={{ height: "500px" }}
          >
            <div
              className="overlay-trigger-container home-overlay-cards"
            // style={{ height: "90%", width: "400px" }}
            >
              <img
                src={categorized_artists}
                alt="categorized_artists"
                className="overlay-trigger"
                style={{ height: "100%" }}
              />
              <div className="overlay flex align-items-center justify-center">
                <div className="overlay-content text-left">
                  <h1 style={{ fontSize: "15px", lineHeight: "20px" }}>
                    Welcome to our Artists Section, where we've meticulously
                    curated a diverse lineup to cater to every musical palate!
                    Navigate seamlessly through a spectrum of talents. Immerse
                    yourself in the Scenic vibes of Electronic Musicians &
                    Producers. Dance to the beats of DJs Unique to your Sound.
                    Unleash your inner Rockstar with Karaoke, revel in the
                    soulful melodies of Singers and Vocalists, and experience
                    the raw beauty of Acoustic performances. Head bang to the
                    powerful tunes of Metals or explore the skillful mastery of
                    instruments with Tools Performers. Whatever your musical
                    inclination, We ensure your journey through a myriad of
                    talents is both effortless and rewarding.
                  </h1>
                </div>
              </div>
            </div>
            <div
              style={{ height: "10%" }}
              className="flex align-items-center justify-center"
            >
              <h1
                style={{
                  left: "50%",
                  color: "white",
                  fontWeight: "600",
                  fontSize: 17,
                }}
              >
                CATEGORIZED ARTISTS
              </h1>
            </div>
          </div>
        </div>
        <div style={{ marginTop: "50px" }} className="footer-overlay">
          <Footer />
        </div>
      </div>
    </div>
  );
}
export default Homepage;
