import React from "react";
import artist_login from "../assets/default_login/artist.jpg";
import outlet_login from "../assets/default_login/outlet.jpg";
import user_login from "../assets/default_login/user.jpg";
import Footer from "../components/Footer";
import "./Videocontent.css";

import { useNavigate } from "react-router-dom";

const Login = () => {
  const navigate = useNavigate();
  return (
    <div className="layout">
      <h1 className="videotext text-center text-light fw-bold ">LOGIN / REGISTER</h1>

      <div className="videoparentsection flex justify-evenly align-items-center flex-wrap mb-5" >

        <div className=" vvideosection1"  >
          <img src={user_login} alt="one" className="threeeventimage" />
          <div className=" eventsectionlefttext">
            <h1 className="image_video_button border-warning "
              onClick={() => navigate("/login/user")} >
              USER
            </h1>
          </div>
        </div>

        <div className=" vvideosection2">
          <img src={outlet_login} alt="one" className="threeeventimage" />
          <div className="eventsectionlefttext" >
            <h1 className="image_video_button border-warning "
              onClick={() => navigate("/login/outlet")} >
              OUTLET
            </h1>
          </div>
        </div>


        <div className=" vvideosection3">
          <img src={artist_login} alt="one" className="threeeventimage" />
          <div className="eventsectionlefttext" >
            <h1 className="image_video_button border-warning"
              onClick={() => navigate("/login/artist")} >
              ARTIST
            </h1>
          </div>
        </div>
      </div>

      <div className="footer-overlay mt-5">
        <Footer />
      </div>



    </div>
  );
};

export default Login;
