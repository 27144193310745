import FacebookIcon from "@mui/icons-material/Facebook";
import GoogleIcon from "@mui/icons-material/Google";
import InstagramIcon from "@mui/icons-material/Instagram";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import StarIcon from "@mui/icons-material/Star";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Rating from "@mui/material/Rating";
import React, { useEffect, useState } from "react";
import { IoIosShareAlt } from "react-icons/io";
import { useNavigate, useParams } from "react-router-dom";
import { RWebShare } from "react-web-share";
import { axiosPrivate } from "../api/axios";
import ReactBigCalendar from "../components/ReactBigCalendar";
import { formatUrl } from '../utils/urls';
import "./Singleoutlet.css";

import { BASE_URL } from "../constants";

const SingleOutlet = () => {
  const [outletData, setOutletData] = useState();
  const navigate = useNavigate();
  const userId = localStorage.getItem("userId");
  const { id } = useParams();
  const [videoUrl, setVideoUrl] = useState("");
  const [outletRating, setOutletRating] = useState(0);
  const [isReviewed, setisReviewed] = useState(false);
  const [residentArtists, setResidentArtists] = useState([]);
  const [isCalendarOpen, setIsCalendarOpen] = React.useState(false);
  const handleCalendarOpen = () => setIsCalendarOpen(true);
  const handleCalendarClose = () => setIsCalendarOpen(false);
  const [isRatingModalOpen, setIsRatingModalOpen] = React.useState(false);
  const handleRatingModalOpen = () => setIsRatingModalOpen(true);
  const handleRatingModalClose = () => setIsRatingModalOpen(false);

  const [form, setForm] = useState({
    food: 0,
    ambience: 0,
    experience: 0,
  });

  const [rating, setRating] = useState([
    {
      category: "food",
      rating: 0,
    },
    {
      category: "ambience",
      rating: 0,
    },
    {
      category: "experience",
      rating: 0,
    },
  ]);

  const [hoverForm, setHoverForm] = useState({
    food: -1,
    ambience: -1,
    experience: -1,
  });

  const style2 = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "800px",
    bgcolor: "rgba(0,0,0,0.5)",
    border: "1px solid yellow",
    boxShadow: 24,
    p: 4,
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "800px",
    bgcolor: "white",
    // border: '2px solid red',
    borderRadius: "5px",
    boxShadow: 24,
    p: 4,
  };

  const labels = {
    1: "Bad",
    2: "Poor",
    3: "Ok",
    4: "Good",
    5: "Excellent",
  };

  function getLabelText(value) {
    return `${value} Star${value !== 1 ? "s" : ""}, ${labels[value]}`;
  }

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Update the form state
    setForm({
      ...form,
      [name]: value,
    });

    // Update the rating state based on the category
    setRating((prevRating) =>
      prevRating.map((item) => {
        if (item.category === name) {
          // Remove the square brackets around e.target.name
          return {
            ...item,
            rating: parseInt(value), // Assuming the rating is a number
          };
        }
        return item;
      })
    );
  };

  const handleHoverChange = (e) => {
    setHoverForm({
      ...hoverForm,
      [e.target.name]: e.target.value,
    });
  };

  const checkReviewed = async () => {
    try {
      let userId = localStorage.getItem("userId");

      const response = await axiosPrivate.get(
        `/ratings/checkReview/${userId}/${id}`
      );
      if (response.status === 200) {
        setisReviewed(true);
      }
    } catch (err) {
      console.error(err);
      // navigate('/login', { state: { from: location }, replace: true });
    }
  };
  const formatTime = (time) => {
    // Splitting the time into hours and minutes

    if (!time) return "";

    const [hours, minutes] = time.split(":");

    // Converting hours and minutes to numbers
    const hoursInt = parseInt(hours, 10);
    const minutesInt = parseInt(minutes, 10);

    // Determining the period (AM or PM)
    const period = hoursInt >= 12 ? "PM" : "AM";

    // Converting hours to 12-hour format
    const formattedHours = (hoursInt % 12 || 12).toString().padStart(2, "0")
    return `${formattedHours}:${minutesInt
      .toString()
      .padStart(2, "0")} ${period}`;
  }
  const getOutletData = async () => {
    try {
      const response = await axiosPrivate.get(`/outlets/${id}`, {});
      console.log("outletd---->", response.data)
      setOutletData(response.data);

    } catch (err) {
      console.error(err);
      // navigate('/login', { state: { from: location }, replace: true });
    }
  };

  useEffect(() => {
    let isMounted = true;

    let userId = localStorage.getItem("userId");
   console.log("user id-->",userId)
    const getResidentArtists = async () => {
      try {
        const response = await axiosPrivate.get(
          `/artistoutletsync/getResidentArtists/${userId}`,
          {
            // // signal: controller.signal
            // 'Access-Control-Allow-Origin' : true
          }
        );
        console.log("respone--------->", response.data)
        setResidentArtists(response.data);

      } catch (err) {
        console.error(err);
        // navigate('/login', { state: { from: location }, replace: true });
      }
    };

    isMounted && getOutletData();
    isMounted && getResidentArtists();
    console.log("residentartist", residentArtists)
    if (userId) {
      isMounted && checkReviewed();
    }

    return () => {
      isMounted = false;
    };
  }, [id]);

  useEffect(() => {
    setVideoUrl(outletData?.intro_video);
    setOutletRating(outletData?.rating || 0);
  }, [outletData]);

  const handleSubmit = async () => {
    try {
      if (!form.food || !form.ambience || !form.experience) {
        alert("Please Select Rating");
      } else {
        const formData = new FormData();

        formData.append("from_id", userId);
        formData.append("to_id", id);

        formData.append("ratings", JSON.stringify(rating));

        const response = await axiosPrivate.post(`/ratings/outlet`, formData, {
          headers: {
            "Content-Type": "application/json",
          },
        });

        if (response.status === 200) {
          alert("Rating Added successfully");
          handleRatingModalClose();
          checkReviewed();
          getOutletData();
        } else {
          alert("Failed to Add Rating");
        }
      }
    } catch (error) {
      console.error("Error updating insights:", error.message);
      alert("An error occurred while updating insights");
    }
  };

  return (
    <div className="layout mb-5">
      <div className="outletparent d-flex flex-wrap mb-5 eachartistparent">
        <div className="eachartistmainsection">
          <div className="mainsection text-light d-flex flex-column mb-5">
            <div className="videosection position-relative overflow-hidden ">
              {videoUrl ? (
                <video
                  autoPlay
                  muted
                  loop
                  className="w-full h-full object-cover max-h-100  z-[-1] background-video2"
                  style={{ width: "100%" }}
                  key="new-video"
                >
                  <source src={`${BASE_URL}${videoUrl}`} type="video/mp4" />
                </video>
              ) : (
                <p className="text-light">No video available.</p>
              )}

              
            </div>

            <div className="bottom-section mb-5 profile_block">
              <div className="artistpicturebottom ">
                <img
                  src={`${BASE_URL}${outletData?.outlet_icon}`}
                  alt="some outlet"
                  className="avatarimg"
                  style={{ objectFit: "cover" }} />

                <div
                  className="border border-warning p-2 rounded mt-3">
                  <h2 className="text-center" style={{ color: "white" }}>
                    {outletData?.outlet_name}
                  </h2>
                </div>
              </div>

              <div className="artiststars mt-3">
                <div className="flex flex-column align-items-center ">
                  <Rating
                    className="mb-3 border-light"
                    color="red"
                    name="half-rating"
                    size="large"
                    precision={0.5}
                    value={outletRating}
                    readOnly
                    emptyIcon={
                      <StarIcon
                        style={{ opacity: 0.2, color: "white" }}
                        fontSize="inherit" />
                    }  />

                  {!isReviewed && userId && userId.startsWith("NC") && (
                    <button onClick={handleRatingModalOpen}
                      className="text-light border rounded p-2 ms-2 " >
                      Review
                    </button>
                  )}
                </div>
              </div>

            </div>

            


            <div className="singleevent-second-banner mt-10">
              <div className="blocks mb-2 ">
                <div className="blocks-content" >
                  {" "}
                  <h1
                    className="text-center h5 mb-4 text-light font-semibold"
                    style={{ fontSize: "17px" }}
                  >
                    INSIGHTS
                  </h1>
                  <div>
                    <div
                      className=" flex align-items-center"
                      style={{ height: "auto", padding: "10px 0px" }}
                    >
                      <h5
                        className=" text-light font-semibold"
                        style={{ minWidth: "150px" }}
                      >
                        CATEGORY :
                      </h5>
                      <h5 className=" text-light ">
                        {outletData?.outlet_category}
                      </h5>
                    </div>

                    <div
                      className=" flex align-items-center "
                      style={{ height: "auto", padding: "10px 0px" }}
                    >
                      <h5
                        className=" text-light font-semibold"
                        style={{ minWidth: "150px" }}
                      >
                        OPEN TIME :
                      </h5>
                      <h5 className=" text-light ">
                        {outletData && formatTime(outletData?.opening_time)}
                      </h5>
                    </div>


                    <div
                      className=" flex align-items-center"
                      style={{ height: "auto", padding: "10px 0px" }}
                    >
                      <h5
                        className=" text-light font-semibold"
                        style={{ minWidth: "150px" }}
                      >
                        CLOSE TIME :
                      </h5>
                      <h5 className=" text-light ">
                        {outletData && formatTime(outletData?.closing_time)}
                      </h5>
                    </div>



                    <div
                      className=" flex align-items-center"
                      style={{ height: "auto", padding: "10px 0px" }}
                    >
                      <h5
                        className=" text-light font-semibold"
                        style={{ minWidth: "150px" }}
                      >
                        WEBSITE :
                      </h5>
                      <h5 className=" text-light ">
                        {outletData?.website ? (
                          <a href={formatUrl(outletData.website)} target="_blank">{outletData.website}</a>
                        ) : (
                          <span>No website available</span> // You can change this to whatever fallback you prefer
                        )}
                      </h5>
                    </div>

                    <div
                      className=" flex align-items-center "
                      style={{ height: "auto", padding: "10px 0px" }}
                    >
                      <h5
                        className=" text-light font-semibold"
                        style={{ minWidth: "150px" }}
                      >
                        ADDRESS :
                      </h5>
                      <div className="flex justify-content-between  w-100">
                        <h5 className=" text-light ">
                          {outletData?.address}
                        </h5>
                      </div>
                    </div>

                    <div
                      className=" flex align-items-center "
                      style={{ height: "auto", padding: "10px 0px" }}
                    >
                      <h5
                        className=" text-light font-semibold"
                        style={{ minWidth: "150px" }}
                      >
                        RESIDENT ARTISTS :
                      </h5>
                      <div className="flex align-items-center  w-100">
                        {residentArtists?.map((artist, index) => (
                          <div
                            onClick={() =>
                              navigate(
                                `/singleartist/${artist.artist_id}/${artist.artist_name}`
                              )
                            }
                            key={index}
                            className=" p-2 border rounded me-3 cursor-pointer"

                          >
                            <p className="text-warning font-semibold">
                              {artist.artist_name}
                            </p>
                          </div>
                        ))}
                      </div>
                    </div>

                    <div className="mt-4  flex justify-evenly">
                      {outletData?.facebook_url && (
                        <FacebookIcon
                          onClick={() =>
                            window.open(outletData?.facebook_url, "_blank")
                          }
                          style={{ color: "white", cursor: "pointer" }}
                        />
                      )}

                      {outletData?.instragram_url && (
                        <InstagramIcon
                          onClick={() =>
                            window.open(outletData?.instragram_url, "_blank")
                          }
                          style={{ color: "white", cursor: "pointer" }}
                        />
                      )}

                      {outletData?.google_url && (
                        <GoogleIcon
                          onClick={() =>
                            window.open(outletData?.google_url, "_blank")
                          }
                          style={{ color: "white", cursor: "pointer" }}
                        />
                      )}

                      {outletData?.map && (
                        <LocationOnIcon
                          onClick={() =>
                            window.open(outletData?.map, "_blank")
                          }
                          style={{ color: "white", cursor: "pointer" }}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="blocks mb-2">
                <div className="blocks-content" >
                  <h1
                    className="text-center h5 mb-4 text-light font-semibold"
                    style={{ fontSize: "17px" }}
                  >
                    SHOWS
                  </h1>
                  <div>
                    <div
                      onClick={() =>
                        navigate("/allevents/upcoming_events", {
                          state: { outletId: outletData.outlet_id },
                        })
                      }
                      className="d-flex justify-content-start align-items-center mb-3 "
                    >
                      <h5 className="card-title text-light cursor-pointer font-semibold mt-4">
                        UPCOMING SHOWS
                      </h5>
                    </div>

                    <div
                      onClick={() =>
                        navigate("/allevents/live_events", {
                          state: { outletId: outletData.outlet_id },
                        })
                      }
                      className="d-flex justify-content-start align-items-center mb-3"
                    >
                      <h5 className="card-title text-light cursor-pointer font-semibold">
                        LIVE SHOWS
                      </h5>
                    </div>

                    <div
                      onClick={() =>
                        navigate("/allevents/previous_events", {
                          state: { outletId: outletData.outlet_id },
                        })
                      }
                      className="d-flex justify-content-start align-items-center mb-3"
                    >
                      <h5 className="card-title text-light cursor-pointer font-semibold">
                        PREVIOUS SHOWS
                      </h5>
                    </div>

                    <div
                      onClick={handleCalendarOpen}
                      className="d-flex justify-content-start align-items-center mb-3"
                    >
                      <h5 className="card-title text-light cursor-pointer font-semibold">
                        CALENDER
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
              <div className="blocks mb-2">
                <div className="blocks-content" >
                  <h1 className="insitetext text-center pe-5 ps-5 text-light font-semibold" style={{ fontSize: "17px" }}>
                    GALLERY
                  </h1>

                  <div className="">
                    <div onClick={() => navigate(`/images/outlets/${outletData.outlet_id}/${outletData.outlet_name}`)}
                      className="d-flex justify-content-start align-items-center mt-3 ">
                      <p className="text-light font-bold cursor-pointer">
                        AMBIENCE
                      </p>
                    </div>

                    <div
                      onClick={() =>
                        navigate(
                          `/videos/experience_videos/${outletData.outlet_id}/${outletData.outlet_name}`
                        )
                      }
                      className="d-flex justify-content-start align-items-center mt-3 cursor-pointer"
                    >
                      <p className="text-light font-bold">EXPERIENCE</p>

                    </div>

                  </div>
                </div>
              </div>
            </div>


            <div className=" mt-10">
              <div className="card blocks-content" >
                <div className="card-body p-0">
                  <h5
                    className="card-title  text-light mb-3 font-semibold"
                    style={{ fontSize: "17px" }} >
                    ABOUT:
                  </h5>
                  <p className="card-text text-light mb-2" // style={{ whiteSpace: "pre" }}
                  >
                    {outletData?.description}
                  </p>
                </div>
              </div>
            </div>

            {/* <div className="disclaimer  mb-5">
              
              <div className=" mb-5 mt-10">
                <div
                  className="card"
                  style={{ backgroundColor: "rgb(186 186 186 / 6%)" }}
                >
                  <div className="card-body">
                    <h5
                      className="card-title  text-light mb-3 font-semibold"
                      style={{ fontSize: "17px" }}
                    >
                      DISCLAIMER{" "}
                    </h5>

                    <p
                      className="card-text text-light mb-4"
                      style={{ whiteSpace: "pre" }}
                    >
                      {outletData?.outlet_profile_rules_and_regulation}
                    </p>
                  </div>
                </div>

                <RWebShare
                  data={{
                    text: "Web Share - Nerolife",
                    url: window.location.href,
                    title: "Nerolife - Event",
                  }}
                >
                  <div className="sharebutton1 cursor-pointer">
                    <h1 className="me-2">Share</h1>
                    <IoIosShareAlt style={{ fontSize: "24px" }} />
                  </div>
                </RWebShare>
              </div>
            </div> */}

            <div className="videosectiondisclaimer2 mb-5 mt-10">
              <div
                className="card blocks-content" >
                <div className="card-body p-0">
                  <h5
                    className="card-title  text-light mb-3 font-semibold"
                    style={{ fontSize: "17px" }}  >
                    DISCLAIMER
                  </h5>

                  <p
                    className="card-text text-light mb-2"
                    style={{ whiteSpace: "pre" }} >
                      {outletData?.outlet_profile_rules_and_regulation}
                  </p>
                </div>
              </div>

              <RWebShare
                data={{
                  text: "Web Share - Nerolife",
                  url: window.location.href,
                  title: "Nerolife - Event",
                }} >
                <div className="sharebutton cursor-pointer">
                  <h1 className="me-2">Share</h1>
                  <IoIosShareAlt style={{ fontSize: "24px" }} />
                </div>
              </RWebShare>
            </div>
          </div>

          {/*  vedio bottom img  */}

        </div>
      </div>

      <Modal
        open={isCalendarOpen}
        onClose={handleCalendarClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description" >
        <Box sx={style2}>
          <ReactBigCalendar outletId={id} />

          {/* <ReactCalendar /> */}
        </Box>
      </Modal>

      <Modal
        open={isRatingModalOpen}
        onClose={handleRatingModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description" >
        <Box sx={style}>
          <h1
            className="text-center font-semibold"
            style={{ fontSize: "17px" }} >
            REVIEW THIS OUTLET
          </h1>

          <div className="flex flex-column">
            <Box
              sx={{
                width: 250,
                display: "flex",
                alignItems: "center",
              }}  >
              <h1 style={{ width: "80px" }} className="me-2 mt-1">
                AMBIENCE :{" "}
              </h1>
              <Rating
                name="ambience"
                value={form.ambience}
                precision={1}
                getLabelText={getLabelText}
                onChange={(e) => handleChange(e)}
                onChangeActive={(e) => handleHoverChange(e)}
                emptyIcon={
                  <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />
                }
              />
              {form.ambience !== null && (
                <Box sx={{ ml: 2 }}>
                  {
                    labels[
                    hoverForm.ambience !== -1
                      ? hoverForm.ambience
                      : form.ambience
                    ]
                  }
                </Box>
              )}
            </Box>

            <Box
              className="flex align-items-center "
              sx={{
                width: 250,
              }} >
              <h1 style={{ width: "80px" }} className="me-2 mt-1">
                FOOD :{" "}
              </h1>
              <Rating
                name="food"
                value={form.food}
                precision={1}
                getLabelText={getLabelText}
                onChange={(e) => handleChange(e)}
                onChangeActive={(e) => handleHoverChange(e)}
                emptyIcon={
                  <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />
                }
              />
              {form.food !== null && (
                <Box sx={{ ml: 2 }}>
                  {labels[hoverForm.food !== -1 ? hoverForm.food : form.food]}
                </Box>
              )}
            </Box>

            <Box
              sx={{
                width: 250,
                display: "flex",
                alignItems: "center",
              }} >
              <h1 style={{ width: "80px" }} className="me-2 mt-1">
                EXPERIENCE :{" "}
              </h1>
              <Rating
                name="experience"
                value={form.experience}
                precision={1}
                getLabelText={getLabelText}
                onChange={(e) => handleChange(e)}
                onChangeActive={(e) => handleHoverChange(e)}
                emptyIcon={
                  <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />
                }
              />
              {form.experience !== null && (
                <Box sx={{ ml: 2 }}>
                  {
                    labels[
                    hoverForm.experience !== -1
                      ? hoverForm.experience
                      : form.experience
                    ]
                  }
                </Box>
              )}
            </Box>
          </div>

          <Button
            className="mt-3"
            style={{ backgroundColor: "#007bff", color: "white" }}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </Box>
      </Modal>

      {/* <div className="footer-overlay">
        <Footer />
      </div> */}
    </div>
  );
};

export default SingleOutlet;
