import React from "react";
import { useState, useEffect } from "react";

// Styles
import "./Outlets.css";
import "./Singleevent.css";
import "../assets/SingleOutlet.css";

import Footer from "../components/Footer";


import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";

import { axiosPrivate } from "../api/axios";

import StarIcon from "@mui/icons-material/Star";

import Rating from "@mui/material/Rating";

import Box from "@mui/material/Box";

import Modal from "@mui/material/Modal";

import { useNavigate, useParams, useLocation } from "react-router-dom";

import Button from "@mui/material/Button";

import { RWebShare } from "react-web-share";

import ReplyIcon from "@mui/icons-material/Reply";

import ArtistCalendar from "../components/ArtistCalendar";

import SoundCloud from "../assets/socialMedia/soundCLoud.jpg";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { IoIosShareAlt } from "react-icons/io";

import { formatUrl } from '../utils/urls';

import { BASE_URL } from "../constants";

const SingleArtist = () => {
  const [artistData, setArtistData] = useState();
  const navigate = useNavigate();

  const userId = localStorage.getItem("userId");

  const [videoUrl, setVideoUrl] = useState("");

  const { id, name } = useParams();

  const [artistRating, setArtistRating] = useState(0);

  const [isReviewed, setisReviewed] = useState(false);

  const [isRatingModalOpen, setIsRatingModalOpen] = React.useState(false);

  const handleRatingModalOpen = () => setIsRatingModalOpen(true);
  const handleRatingModalClose = () => setIsRatingModalOpen(false);

  const [isCalendarOpen, setIsCalendarOpen] = React.useState(false);

  const handleCalendarOpen = () => {
    if (artistData.authentication_status) {
      setIsCalendarOpen(true);
    } else {
      alert(
        "This Account Has Been Limited By The System. \nPlease Contact Support Team To Resolve This."
      );
    }
  };

  const handleCalendarClose = () => setIsCalendarOpen(false);

  const [form, setForm] = useState({
    music: 0,
    shows: 0,
  });

  const [rating, setRating] = useState([
    {
      category: "music",
      rating: 0,
    },
    {
      category: "shows",
      rating: 0,
    },
  ]);

  const [hoverForm, setHoverForm] = useState({
    music: -1,
    shows: -1,
  });

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "800px",
    bgcolor: "rgb(0,0,0,0.5)",
    borderRadius: "5px",
    boxShadow: 24,
    p: 4,
  };

  const style2 = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "800px",
    bgcolor: "rgba(0,0,0,0.5)",
    border: "1px solid yellow",
    boxShadow: 24,
    p: 4,
  };

  const labels = {
    1: "Bad",
    2: "Poor",
    3: "Ok",
    4: "Good",
    5: "Excellent",
  };

  function getLabelText(value) {
    return `${value} Star${value !== 1 ? "s" : ""}, ${labels[value]}`;
  }

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Update the form state
    setForm({
      ...form,
      [name]: value,
    });

    // Update the rating state based on the category
    setRating((prevRating) =>
      prevRating.map((item) => {
        if (item.category === name) {
          // Remove the square brackets around e.target.name
          return {
            ...item,
            rating: parseInt(value), // Assuming the rating is a number
          };
        }
        return item;
      })
    );
  };

  const handleHoverChange = (e) => {
    setHoverForm({
      ...hoverForm,
      [e.target.name]: e.target.value,
    });
  };

  const checkReviewed = async () => {
    try {
      let userId = localStorage.getItem("userId");
      if (!userId) return;
      const response = await axiosPrivate.get(
        `/ratings/checkReview/${userId}/${id}`
      );
      if (response.status === 200) {
        setisReviewed(true);
      }
    } catch (err) {
      console.error(err);
      // navigate('/login', { state: { from: location }, replace: true });
    }
  };

  const getartistData = async () => {
    try {
      const response = await axiosPrivate.get(`/artists/${id}`, {
        // // signal: controller.signal
        // 'Access-Control-Allow-Origin' : true
      });
      setArtistData(response.data);
      setArtistRating(response.data.rating || 0);
    } catch (err) {
      console.error(err);
      // navigate('/login', { state: { from: location }, replace: true });
    }
  };

  const handleSubmit = async () => {
    try {
      let userId = localStorage.getItem("userId");
      if (!form.music || !form.shows) {
        alert("Please Select Rating");
      } else {
        const formData = new FormData();

        formData.append("from_id", userId);
        formData.append("to_id", id);

        formData.append("ratings", JSON.stringify(rating));

        const response = await axiosPrivate.post(`/ratings/artist`, formData, {
          headers: {
            "Content-Type": "application/json",
          },
        });

        if (response.status === 200) {
          alert("Rating Added successfully");
          handleRatingModalClose();
          checkReviewed();
          getartistData();
        } else {
          alert("Failed to Add Rating");
        }
      }
    } catch (error) {
      console.error("Error updating insights:", error.message);
      alert("An error occurred while updating insights");
    }
  };

  const [residentOutlets, setResidentOutlets] = useState([]);

  useEffect(() => {
    let isMounted = true;

    isMounted && getartistData();
    if (id) {
      isMounted && checkReviewed();
    }

    return () => {
      isMounted = false;
    };
  }, [id]);

  useEffect(() => {
    let isMounted = true;

    setVideoUrl(artistData?.artist_intro_video);

    const getresidentOutlets = async () => {
      try {
        const response = await axiosPrivate.get(
          `/artistoutletsync/getResidentOutlets/${id}`,
          {
            // // signal: controller.signal
            // 'Access-Control-Allow-Origin' : true
          }
        );
        setResidentOutlets(response.data);
      } catch (err) {
        console.error(err);
        // navigate('/login', { state: { from: location }, replace: true });
      }
    };

    isMounted && getresidentOutlets();

    return () => {
      isMounted = false;
    };
  }, [artistData]);

  return (
    <div className="layout mb-5">
      <div className="outletparent d-flex flex-wrap mb-5 eachartistparent">
        <div className="eachartistmainsection">
          <div className="mainsection text-light d-flex flex-column mb-5">
            <div className="videosection position-relative overflow-hidden ">
              {videoUrl ? (
                <video
                  autoPlay
                  muted
                  loop
                  className="w-full h-full object-cover max-h-100  z-[-1] background-video2"
                  style={{ width: "100%" }}
                  key="new-video"
                >
                  <source src={`${BASE_URL}${videoUrl}`} type="video/mp4" />
                </video>
              ) : (
                <p>No video available.</p>
              )}
            </div>

            <div className="bottom-section mb-5 profile_block">
              <div className="artistpicturebottom ">
                <img  src={`${BASE_URL}${artistData?.artist_profile_icon}`}
                  alt="some outlet"
                  className="avatarimg"
                  style={{ objectFit: "cover" }} />

                <div className="border border-warning p-2 rounded mt-3">
                  <h2 className="text-center" style={{ color: "white" }}>
                    {artistData?.artist_stage_name}
                  </h2>
                </div>
              </div>

              <div className="artiststars mt-3">
                <div className="flex flex-column align-items-center">
                <Rating className="mb-3 border-light"
                  color="red"
                  name="half-rating"
                  size="large"
                  precision={0.5}
                  value={artistRating}
                  readOnly
                  emptyIcon={
                    <StarIcon
                      style={{ opacity: 0.2, color: "white" }}
                      fontSize="inherit"
                    />
                  }
                />

                {!isReviewed && userId && userId.startsWith("NC") && (
                  <button
                    onClick={handleRatingModalOpen}
                    className="text-light border rounded p-2 ms-2 " >
                    Review
                  </button>
                )}
                </div>
              </div>

            </div>


           

            

            <div className="singleevent-second-banner mt-10">
              <div className="blocks mb-2">
                <div className=" blocks-content">
                  {" "}
                  <h1
                    className="text-center h5 mb-4 text-light font-semibold"
                    style={{ fontSize: "17px" }}
                  >
                    INSIGHTS
                  </h1>
                  <div>
                    <div
                      className=" flex align-items-center"
                      style={{ height: "auto", padding: "10px 0px" }}
                    >
                      <h5
                        className=" text-light font-semibold"
                        style={{ minWidth: "150px" }}
                      >
                        CATEGORY :
                      </h5>
                      <h5 className=" text-light text-uppercase">
                        {artistData?.artist_category}
                      </h5>
                    </div>

                    <div
                      className=" flex align-items-center "
                      style={{ height: "auto", padding: "10px 0px" }}
                    >
                      <h5
                        className=" text-light font-semibold"
                        style={{ minWidth: "150px" }}
                      >
                        MUSIC OPERATION:
                      </h5>
                      <h5 className=" text-light ">
                        {artistData?.music_operations}
                      </h5>
                    </div>

                    <div
                      className=" flex align-items-center "
                      style={{ height: "auto", padding: "10px 0px" }}
                    >
                      <h5
                        className=" text-light font-semibold"
                        style={{ minWidth: "150px" }}
                      >
                        WEBSITE :
                      </h5>
                      <h5 className=" text-light ">
                        {artistData?.website ? (
                              <a href={formatUrl(artistData.website)} target="_blank">{artistData.website}</a>
                            ) : (
                              <span>No website available</span> // You can change this to whatever fallback you prefer
                            )}
                        </h5>
                    </div>

                    <div
                      className=" flex align-items-center "
                      style={{ height: "auto", padding: "10px 0px" }}
                    >
                      <h5
                        className=" text-light font-semibold"
                        style={{ minWidth: "150px" }}
                      >
                        RESIDENT OUTLETS :
                      </h5>
                      <div className="flex align-items-center  w-100">
                        {residentOutlets?.map((outlet, index) => (
                          <div
                            onClick={() =>
                              navigate(
                                `/singleoutlet/${
                                  outlet.outlet_id
                                }/${outlet.outlet_name.replace(/ /g, "_")}`
                              )
                            }
                            key={index}
                            className=" p-2 border rounded me-3 cursor-pointer"
                          >
                            <p className="text-warning font-semibold">
                              {outlet.outlet_name}
                            </p>
                          </div>
                        ))}
                      </div>
                    </div>

                    <div className="mt-4  flex justify-evenly">
                      {artistData?.facebook_url && (
                        <FacebookIcon
                          onClick={() =>
                            window.open(artistData.facebook_url, "_blank")
                          }
                          style={{ color: "white", cursor: "pointer" }}
                        />
                      )}

                      {artistData?.instragram_url && (
                        <InstagramIcon
                          onClick={() =>
                            window.open(artistData.instragram_url, "_blank")
                          }
                          style={{ color: "white", cursor: "pointer" }}
                        />
                      )}

                      {artistData?.soundcloud_url && (
                        <img className="ico-soundcloud" alt="img"
                          onClick={() => window.open(artistData.soundcloud_url, "_blank")} src={SoundCloud} />
                      )}

                      {artistData?.youtube_url && (
                        <YouTubeIcon
                          onClick={() => window.open(artistData.youtube_url, "_blank") }
                          style={{ color: "white", cursor: "pointer" }}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="blocks mb-2">
                <div className=" blocks-content">
                  <h1
                    className="text-center h5 mb-4 text-light font-semibold"
                    style={{ fontSize: "17px" }}
                  >
                    SHOWS
                  </h1>
                  <div>
                    <div
                      onClick={() =>
                        navigate("/allevents/upcoming_events", {
                          state: { artistId: artistData.artist_id },
                        })
                      }
                      className="d-flex justify-content-start align-items-center mb-3" >
                      <h5 className="card-title text-light cursor-pointer font-semibold  mt-2">
                        UPCOMING SHOWS
                      </h5>
                    </div>

                    <div
                      onClick={() =>
                        navigate("/allevents/live_events", {
                          state: { artistId: artistData.artist_id },
                        })
                      }
                      className="d-flex justify-content-start align-items-center mb-3">
                      <h5 className="card-title text-light cursor-pointer font-semibold">
                        LIVE SHOWS
                      </h5>
                    </div>

                    <div
                      onClick={() =>
                        navigate("/allevents/previous_events", {
                          state: { artistId: artistData.artist_id },
                        })
                      }
                      className="d-flex justify-content-start align-items-center mb-3">
                      <h5 className="card-title text-light cursor-pointer font-semibold">
                        PREVIOUS SHOWS
                      </h5>
                    </div>

                    <div className="d-flex justify-content-start align-items-center mb-3">
                      <h5
                        onClick={handleCalendarOpen}
                        className="card-title text-light cursor-pointer font-semibold">
                        CALENDER
                      </h5>
                    </div>
                  </div>
                </div>
              </div>

              <div className="blocks mb-2">
                <div className=" blocks-content">
                  <h1
                    className="insitetext text-center pe-5 ps-5 text-light font-semibold"
                    style={{ fontSize: "17px" }}
                  >
                    GALLERY
                  </h1>

                  <div className="">
                    <div
                      onClick={() =>
                        navigate(
                          `/images/artists/${artistData.artist_id}/${name}`
                        )
                      }
                      className="d-flex justify-content-start  mb-3"
                    >
                      <h5 className="card-title text-light cursor-pointer font-semibold mt-2">
                        POTRAITS
                      </h5>
                    </div>

                    <div
                      onClick={() =>
                        navigate(
                          `/videos/show_videos/${artistData.artist_id}/${name}`
                        )
                      }
                      className="d-flex justify-content-start  mb-3"
                    >
                      <h5 className="card-title text-light cursor-pointer font-semibold ">
                        SNIPPETS
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className=" mt-10">
              <div
                className="card blocks-content"
              >
                <div className="card-body p-0">
                  <h5
                    className="card-title  text-light mb-3 font-semibold"
                    style={{ fontSize: "17px" }}
                  >
                    ABOUT:
                  </h5>
                  <p
                    className="card-text text-light mb-2"
                    // style={{ whiteSpace: "pre" }}
                  >
                    {artistData?.description}
                  </p>
                </div>
              </div>
            </div>

            <div className="disclaimer mb-5">
              <div className="videosectiondisclaimer2 mb-5 mt-10">
                <div
                  className="card  blocks-content" 
                >
                  <div className="card-body p-0">
                    <h5
                      className="card-title  text-light mb-3 font-semibold"
                      style={{ fontSize: "17px" }}
                    >
                      DISCLAIMER{" "}
                    </h5>

                    <p
                      className="card-text text-light mb-4"
                      style={{ whiteSpace: "pre" }}
                    >
                      {artistData?.artist_profile_disclaimer}
                    </p>
                  </div>
                </div>

                <RWebShare
                  data={{
                    text: "Web Share - Nerolife",
                    url: window.location.href,
                    title: "Nerolife - Event",
                  }}
                >
                  <div className="sharebutton1 cursor-pointer">
                    <h1 className="me-2">Share</h1>
                    <IoIosShareAlt style={{ fontSize: "24px" }} />
                  </div>
                </RWebShare>
              </div>
            </div>
          </div>

         
        </div>
      </div>

      <Modal
        open={isRatingModalOpen}
        onClose={handleRatingModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="border border-warning">
          <h1
            className="text-center text-light font-semibold mb-4"
            style={{ fontSize: "17px" }}
          >
            REVIEW THIS ARTIST
          </h1>

          <div className="flex flex-column">
            <Box
              sx={{
                width: 250,
                display: "flex",
                alignItems: "center",
              }}
            >
              <h1 style={{ width: "80px" }} className="me-2 mt-1 text-light">
                MUSIC :{" "}
              </h1>
              <Rating
                name="music"
                value={form.music}
                precision={1}
                getLabelText={getLabelText}
                onChange={(e) => handleChange(e)}
                onChangeActive={(e) => handleHoverChange(e)}
                emptyIcon={
                  <StarIcon
                    style={{ opacity: 0.55, color: "white" }}
                    fontSize="inherit"
                  />
                }
              />
              {form.music !== null && (
                <Box sx={{ ml: 2, color: "white" }}>
                  {
                    labels[
                      hoverForm.music !== -1 ? hoverForm.music : form.music
                    ]
                  }
                </Box>
              )}
            </Box>
          </div>

          <div className="flex flex-column">
            <Box
              sx={{
                width: 250,
                display: "flex",
                alignItems: "center",
              }}
            >
              <h1 style={{ width: "80px" }} className="me-2 mt-1 text-light">
                SHOWS :{" "}
              </h1>
              <Rating
                name="shows"
                value={form.shows}
                precision={1}
                getLabelText={getLabelText}
                onChange={(e) => handleChange(e)}
                onChangeActive={(e) => handleHoverChange(e)}
                emptyIcon={
                  <StarIcon
                    style={{ opacity: 0.55, color: "white" }}
                    fontSize="inherit"
                  />
                }
              />
              {form.shows !== null && (
                <Box sx={{ ml: 2, color: "white" }}>
                  {
                    labels[
                      hoverForm.shows !== -1 ? hoverForm.shows : form.shows
                    ]
                  }
                </Box>
              )}
            </Box>
          </div>

          <Button
            className="mt-3"
            style={{ backgroundColor: "#007bff", color: "white" }}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </Box>
      </Modal>

      <Modal
        open={isCalendarOpen}
        onClose={handleCalendarClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style2}>
          <ArtistCalendar
            artistId={artistData?.artist_id}
            artistName={artistData?.artist_stage_name}
          />

          {/* <ReactBigCalendar  /> */}

          {/* <ReactCalendar /> */}
        </Box>
      </Modal>

      {/* <div className="footer-overlay">
        <Footer />
      </div> */}
    </div>
  );
};

export default SingleArtist;
