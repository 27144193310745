import React, { useEffect, useState } from "react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import nightcube_dashbord_background from "../assets/background/nightcube_background.mp4";

import CloseFullscreenIcon from "@mui/icons-material/CloseFullscreen";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import axios, { axiosPrivate } from "../api/axios";
import welcome_background from "../assets/background/WELCOME.mp4";



import CropFreeIcon from "@mui/icons-material/CropFree";
import FilterListIcon from "@mui/icons-material/FilterList";
import InsightsIcon from "@mui/icons-material/Insights";

import useAuth from "../hooks/useAuth";

import "./NightCubeSystems.css";


import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import DateRangeIcon from '@mui/icons-material/DateRange';
import SpatialAudioOffIcon from '@mui/icons-material/SpatialAudioOff';

import DynamicFormIcon from '@mui/icons-material/DynamicForm';
import Modal from "@mui/material/Modal";

import PercentIcon from '@mui/icons-material/Percent';
import ViewInArIcon from '@mui/icons-material/ViewInAr';

import RssFeedIcon from '@mui/icons-material/RssFeed';

import StackedLineChartIcon from '@mui/icons-material/StackedLineChart';

import CloseIcon from "@mui/icons-material/Close";

function NightcubeSystems() {
  const navigate = useNavigate();

  const [username, setusername] = useState(localStorage.getItem("userName"));
  const [userId] = useState(localStorage.getItem("userId"));
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [docCount, setDocCount] = useState({});
  const [admins, setAdmins] = useState([]);
  const [userRole, setUserRole] = useState("");

  const [picture, setPicture] = useState(null);

  const [blog, setBlog] = useState("");

  const [nightcubeSetup, setNightcubeSetup] = useState("");

  const [isFilterSelected, setIsFilterSelected] = useState(false);

  const location = useLocation();

  const [nightCubeSetUpModal, setNightCubeSetUpModal] = React.useState(false);

  const handleNightCubeSetUpOpen = () => setNightCubeSetUpModal(true);
  const handleNightCubeSetUpClose = () => setNightCubeSetUpModal(false);

  const [setUpcompleteModal, setSetUpcompleteModal] = React.useState(false);

  const handleOpenSetUpcompleteModal = () => setSetUpcompleteModal(true);
  const handleCloseSetUpcompleteModal = () => setSetUpcompleteModal(false);

  const [storiesModal, setStoriesModal] = React.useState(false);

  const handleOpenstoriesModal = () => setStoriesModal(true);
  const handleClosestoriesModal = () => setStoriesModal(false);

  const [idError, setIdError] = useState(false);
  const [gstError, setGstError] = useState(false);


  const [form, setForm] = useState({
    company_identification_number: "",
    gstin: "",
    gst_percentage: "",
    outlet_name: "",
    outlet_id: "",
    email: "",
  });

  useEffect(() => {
    let isMounted = true;

    const getAdmins = async () => {
      try {
        const response = await axios.get(`/user/admins`, {
          // signal: controller.signal
        });
        setAdmins(response.data);
        console.log("admins", response.data)
      } catch (err) {
        console.error(err);
      }
    };

    const getDocCount = async () => {
      try {
        const response = await axios.get(`/documents`, {
          // signal: controller.signal
        });
        isMounted && setDocCount(response.data);
      } catch (err) {
        console.error(err);
      }
    };

    isMounted && getDocCount();
    isMounted && getAdmins();

    return () => {
      isMounted = false;
      // controller.abort();
    };
  }, []);

  const sendmessages = async () => {
    try {
      console.log("outlet", form.outlet_name)
      const response = await axiosPrivate.post(
        `/inbox`,
        {
          "message_receivers": JSON.stringify(
            [
              ...admins
                .filter(admin => admin.role != "admin1")
                .map(admin => ({
                  'id': admin.userId,
                  'email_id': admin.email,
                  'name': admin.first_name + ' ' + admin.last_name
                })),
              {
                'id': form.outlet_id,

              }
            ]
          ),
          "subject": "Registered Outlet Linked To NIGHTCUBE ",
          "message": `Congratulations ${form.outlet_name},

                  Welcome To NIGHTCUBE

                Recorded Details :
                CIN : ${form.company_identification_number}
                GST : ${form.gstin}
                GST % : ${form.gst_percentage}`,
          "category": "System"

        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }


      );




    } catch (error) {
      console.error("Error sending message:", error.message);
      alert("An error occurred while sending message");
    }
  };
  const sendGSTMessage = async () => {

    const response = await axios.post('/user/sendGSTMessage ', {
      email: form.email,
      company_identification_number: form.company_identification_number,
      gstin: form.gstin,
      gst_percentage: form.gst_percentage,
      outlet_name: form.outlet_name
    });
  }
  console.log("cin-->", form.company_identification_number)
  useEffect(() => {
    // Function to close the modal
    function closeModal() {
      setSetUpcompleteModal(false); // Set showModal to false to close the modal
    }

    // Set a timeout to close the modal after 5 seconds if it is open
    if (setUpcompleteModal) {
      const timer = setTimeout(closeModal, 4000); // 5000 milliseconds = 5 seconds

      // Clean up the timeout if the component unmounts or showModal becomes false before the timeout expires
      return () => clearTimeout(timer);
    }
  }, [setUpcompleteModal]); // useEffect will re-run whenever showModal changes

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "500px",
    bgcolor: "rgba(0,0,0,0.5)",
    boxShadow: 24,
    color: 'white',
    p: 4,
  };

  const style2 = {
    position: "absolute",
    width: "100%",
    height: '100vh',
    boxShadow: 24,
    color: 'white',
    p: 4,
  };

  const handleChange = (e) => {
    ;

    if (e.target.name === 'company_identification_number') {
      if (e.target.value.length > 0 && e.target.value.length !== 21) {
        setIdError(true);
      }
      else {
        setIdError(false);
        setForm({
          ...form,
          [e.target.name]: e.target.value,
        });
      }


    } else if (e.target.name === 'gstin') {
      if (e.target.value.length > 0 && e.target.value.length !== 15) {
        setGstError(true);
      }
      else {
        setGstError(false);
        setForm({
          ...form,
          [e.target.name]: e.target.value,
        });
      }
    } else {
      setForm({
        ...form,
        [e.target.name]: e.target.value,
      });
    }





  };

  useEffect(() => {
    const getUserData = async () => {
      try {

        let userId = await localStorage.getItem("userId");
        if (!userId) return;
        const response = await axiosPrivate.get(`/user/${userId}`, {
          // // signal: controller.signal
          // 'Access-Control-Allow-Origin' : true
        });
        setUserRole(response.data.role);

      } catch (err) {
        console.error(err);
        // navigate('/login', { state: { from: location }, replace: true });
      }
    };

    getUserData();
  }, []);

  useEffect(() => {

    let isMounted = true;


    const getOutletData = async () => {
      try {
        let userId = await localStorage.getItem("userId");
        const response = await axiosPrivate.get(`/outlets/${userId}`, {
          // // signal: controller.signal
          // 'Access-Control-Allow-Origin' : true
        });
        const email = response.data.email
        const outlet_name = response.data.outlet_name
        const outlet_id = response.data.outlet_id
        setNightcubeSetup(response.data.nightcube_setup);

        if (!response.data.nightcube_setup) {
          handleNightCubeSetUpOpen()
        }
        if (isMounted) {
          setForm(prevForm => ({
            ...prevForm,
            email: email || "",
            outlet_name: outlet_name || "",
            outlet_id: outlet_id || "",
          }));
        }

      } catch (err) {
        console.error(err);
        // navigate('/login', { state: { from: location }, replace: true });
      }
    };

    if (userRole === 'outlet') {
      isMounted && getOutletData();
    }



    return () => {
      isMounted = false;
    }


  }, [userRole]);

  const { auth } = useAuth();

  const [state, setState] = React.useState({
    left: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box sx={{ width: 250, position: "relative" }} role="presentation">
      <CloseFullscreenIcon
        onClick={toggleDrawer(anchor, false)}
        onKeyDown={toggleDrawer(anchor, false)}
        style={{
          position: "absolute",
          right: "5px",
          top: "5px",
          cursor: "pointer",
        }}
      />

      <List className="mt-4">
        <ListItem
          onClick={toggleDrawer(anchor, false)}
          onKeyDown={toggleDrawer(anchor, false)}
          disablePadding
        >
          <ListItemButton
            onClick={() => navigate("/nightcube")}
          >
            <ListItemIcon>
              <ViewInArIcon />
            </ListItemIcon>
            <ListItemText>Home </ListItemText>
          </ListItemButton>
        </ListItem>

        <ListItem
          onClick={toggleDrawer(anchor, false)}
          onKeyDown={toggleDrawer(anchor, false)}
          disablePadding
        >
          <ListItemButton onClick={() => navigate("/nightcube/nightcube-inbox", { state: { userRole: userRole } })}>
            <ListItemIcon>
              <InboxIcon />
            </ListItemIcon>
            <ListItemText>Inbox </ListItemText>
          </ListItemButton>
        </ListItem>

        <ListItem
          onClick={() => setIsFilterSelected(!isFilterSelected)}
          onKeyDown={() => setIsFilterSelected(!isFilterSelected)}
          disablePadding
        >
          <ListItemButton>
            <ListItemIcon>
              <FilterListIcon />
            </ListItemIcon>
            <ListItemText>Filter </ListItemText>

            {isFilterSelected ?
              <ArrowDropUpIcon />
              :
              <ArrowDropDownIcon />
            }



          </ListItemButton>
        </ListItem>

        {(isFilterSelected && userRole !== 'admin2') &&

          <ListItem
            className="ms-3"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
            disablePadding
          >
            <ListItemButton onClick={() => navigate("/nightcube/customer-filter", { state: { userRole: userRole } })}>
              <ListItemIcon>
                <AccountCircleIcon />
              </ListItemIcon>
              <ListItemText>Customers </ListItemText>
            </ListItemButton>
          </ListItem>


        }

        {(isFilterSelected && (userRole === 'admin1' || userRole === 'superadmin')) &&
          <ListItem
            className="ms-3"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
            disablePadding
          >
            <ListItemButton onClick={() => navigate("/nightcube/events-filter", { state: { userRole: userRole } })}>
              <ListItemIcon>
                <DateRangeIcon />
              </ListItemIcon>
              <ListItemText>Events </ListItemText>
            </ListItemButton>
          </ListItem>
        }

        {(isFilterSelected && (userRole === 'admin2' || userRole === 'superadmin')) &&
          <ListItem
            className="ms-3"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
            disablePadding
          >
            <ListItemButton onClick={() => navigate("/nightcube/outlets-filter", { state: { userRole: userRole } })}>
              <ListItemIcon>
                <AccountBalanceIcon />
              </ListItemIcon>
              <ListItemText>Outlets </ListItemText>
            </ListItemButton>
          </ListItem>
        }

        {(isFilterSelected && (userRole === 'admin1' || userRole === 'superadmin')) &&
          <ListItem
            className="ms-3"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
            disablePadding
          >
            <ListItemButton onClick={() => navigate("/nightcube/artists-filter", { state: { userRole: userRole } })}>
              <ListItemIcon>
                <SpatialAudioOffIcon />
              </ListItemIcon>
              <ListItemText>Artists </ListItemText>
            </ListItemButton>
          </ListItem>
        }

        {userRole === 'superadmin' &&
          <ListItem
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
            disablePadding
          >
            <ListItemButton onClick={() => navigate("/nightcube/nightcube-ROAS")}>
              <ListItemIcon>
                <InsightsIcon />
              </ListItemIcon>
              <ListItemText>ROAS System </ListItemText>
            </ListItemButton>
          </ListItem>

        }

        {(userRole === 'outlet') &&
          <ListItem
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
            disablePadding
          >
            <ListItemButton onClick={() => navigate("/nightcube/survey-form")}>
              <ListItemIcon>
                <DynamicFormIcon />
              </ListItemIcon>
              <ListItemText>Survey Form </ListItemText>
            </ListItemButton>
          </ListItem>
        }


        {(userRole !== 'admin1' && userRole !== 'admin2') &&
          <ListItem
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
            disablePadding
          >
            <ListItemButton onClick={() => navigate("/nightcube/nightcube-POS", { state: { userRole: userRole } })}>
              <ListItemIcon>
                <CropFreeIcon />
              </ListItemIcon>
              <ListItemText>POS System </ListItemText>
            </ListItemButton>
          </ListItem>
        }



      </List>
      {/* <Divider /> */}
    </Box>
  );

  const handleSubmit = async () => {
    try {
      if (
        !form.company_identification_number ||
        !form.gstin ||
        !form.gst_percentage
      ) {
        alert("Please Select All Fields");
      } else {
        const formData = new FormData();

        for (const key in form) {
          formData.append(key, form[key]);
        }

        const response = await axiosPrivate.patch(
          `/outlets/nightcube/${userId}`,
          formData
        );

        sendmessages();
        sendGSTMessage();



        if (response.status === 200) {
          alert("Nightcube Setup Complete");
          handleNightCubeSetUpClose();
          handleOpenSetUpcompleteModal();
        } else {
          alert("Server Error");
        }
      }
    } catch (error) {
      console.error("Error updating insights:", error.message);
      alert("An error occurred while Setup your Nightcube");
    }
  };

  const handleSubmitStory = async () => {
    try {
      if (
        !picture ||
        !blog
      ) {
        alert("Please Select All Fields");
      } else {
        const formData = new FormData();



        formData.append("picture", picture);
        formData.append("message", blog);

        const response = await axiosPrivate.post(
          `/stories/post`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );



        if (response.status === 200) {
          alert("Stories Added Successfully");
          handleClosestoriesModal();
        } else {
          alert("Server Error");
        }
      }
    } catch (error) {
      console.error("Error Updating Stories:", error.message);
      alert("An error occurred while Adding Stories");
    }
  }

  const handleChangePicture = (e) => {
    ;
    const allowedTypes = ["image/png", "image/jpeg", "image/jpg"];

    if (
      allowedTypes.includes(e.target.files[0].type)
    ) {
      setPicture(e.target.files[0]);
    } else {
      alert("Invalid file type. Please select a PNG, JPG, or JPEG image.");
    }
  };

  return (
    <div className="bgContainer">
      <div className="overlay2">
        <video
          className="bgVideo"
          src={nightcube_dashbord_background}
          autoPlay
          loop
          muted
        />
        <div className="content navbarsection">
          <div className="flex justify-content-center ">
            <h4 style={{ display: 'inline-block', fontSize: "20px", fontWeight: "500" }}
              className="text-center text-light px-3 py-2 border border-warning rounded" >
              {username}
            </h4>
          </div>

          <div className="navsection  d-flex  justify-content-start align-items-center ">
            <div className="leftside flex-grow-1">
              <button
                className="btn"
                type="button"
                onClick={() => setIsSidebarOpen(!isSidebarOpen)}
              >
                {["left"].map((anchor) => (
                  <React.Fragment key={anchor}>
                    <Button
                      className="text-light border"
                      onClick={toggleDrawer(anchor, true)} >
                      <MenuOpenIcon style={{ fontSize: "35" }} />
                    </Button>
                    <Drawer
                      anchor={anchor}
                      open={state[anchor]}
                      onClose={toggleDrawer(anchor, false)}      >
                      {list(anchor)}
                    </Drawer>
                  </React.Fragment>
                ))}
              </button>
            </div>



            <div className="rightside flex-grow-1 w-100  ">


              <ul className="nav nav-underline d-flex justify-content-evenly">

                {userRole !== 'admin2' &&
                  <li className="nav-item flex-grow-1 text-center">


                    <Link
                      className="nav-link text-light "
                      style={{

                        fontWeight: "600",
                        borderColor: "#013649",


                        display: userRole === 'outlet' ? "inline-block" : 'block',
                        marginRight: userRole === 'outlet' ? "90px" : '0',
                        fontSize: userRole === 'outlet' && '20px'


                      }}
                      to="/nightcube/nightcube-customers"
                      state={{
                        userRole: userRole
                      }}
                    >
                      CUSTOMERS
                      <StackedLineChartIcon className="ms-2 mb-2" />
                    </Link>
                  </li>
                }

                {(userRole === 'admin1' || userRole === 'superadmin') &&
                  <li className="nav-item flex-grow-1 text-center">
                    <Link
                      className="nav-link text-light"
                      style={{ fontWeight: "600", borderColor: "#013649" }}
                      to="/nightcube/nightcube-events"
                    >
                      EVENTS
                      <StackedLineChartIcon className="ms-2 mb-2" />
                    </Link>
                  </li>
                }

                {(userRole === 'admin2'
                  || userRole === 'superadmin') &&
                  <li className="nav-item flex-grow-1 text-center">
                    <Link
                      className="nav-link text-light "
                      style={{ fontWeight: "600", borderColor: "#013649" }}
                      to="/nightcube/nightcube-outlets"
                    >
                      OUTLETS
                      <StackedLineChartIcon className="ms-2 mb-2" />
                    </Link>
                  </li>

                }

                {userRole === 'admin2' &&
                  <li className="nav-item flex-grow-1 text-center">
                    <Link
                      className="nav-link text-light "
                      style={{ fontWeight: "600", borderColor: "#013649" }}
                      to="/nightcube/nightcube-POS"
                      state={{
                        userRole: userRole
                      }}
                    >
                      POINT OF SALES
                      <CropFreeIcon className="ms-2 mb-2" />
                    </Link>
                  </li>

                }

                {userRole === 'admin2' &&
                  <li className="nav-item flex-grow-1 text-center">
                    <li
                      className="nav-link text-light cursor-pointer"
                      style={{ fontWeight: "600", borderColor: "#013649" }}
                      onClick={handleOpenstoriesModal}
                    >
                      STORIES
                      <RssFeedIcon className="ms-2 mb-2" />
                    </li>
                  </li>
                }


                {(userRole === 'admin1' || userRole === 'superadmin') &&
                  <li className="nav-item flex-grow-1 text-center">
                    <Link
                      className="nav-link text-light"
                      style={{ fontWeight: "600", borderColor: "#013649" }}
                      to="/nightcube/nightcube-artist"
                    >
                      ARTISTS
                      <StackedLineChartIcon className="ms-2 mb-2" />
                    </Link>
                  </li>
                }
              </ul>
            </div>
          </div>
          {/* Place any content specific to the /nightcube route here */}
          <Outlet /> {/* This will render child routes */}
        </div>
      </div>

      <Modal
        open={nightCubeSetUpModal}
        // onClose={handleNightCubeSetUpClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="border border-warning rounded" sx={style}>
          <h1 className="text-center text-light font-semibold" style={{ fontSize: '22px' }}>Set Up Your NIGHTCUBE System</h1>
          <div className="mt-4">
            <div className="mt-3 mx-2">
              <label
                style={{ color: "white" }}
                for="exampleFormControlInput1"
                className="form-label mb-2"
              >
                CORPORATE IDENTIFICATION NUMBER
              </label>
              <input
                type="text"
                name="company_identification_number"
                placeholder="Company Identification Number"
                onChange={handleChange}
                className="form-control"

              />

              {idError && <p className="mt-2 text-danger">CIN Should be  21 digits</p>}
            </div>

            <div className="mt-3 mx-2">
              <label
                style={{ color: "white" }}
                for="exampleFormControlInput1"
                className="form-label mb-2"
              >
                GST IDENTIFICATION NUMBER
              </label>
              <input
                type="text"
                name="gstin"
                placeholder="Gst Number"
                onChange={handleChange}
                className="form-control"

              />
              {gstError && <p className="mt-2 text-danger">GST Should be  15 digits</p>}
            </div>

            <div className="mt-3 mx-2">
              <label
                style={{ color: "white" }}
                for="exampleFormControlInput1"
                className="form-label mb-2"
              >
                GST PERCENTAGE
              </label>
              <div className="input-group mb-3">
                <span className="input-group-text">
                  <PercentIcon />
                </span>
                <input
                  type="number"
                  name="gst_percentage"
                  placeholder="Gst Percentage"
                  onChange={handleChange}
                  className="form-control"

                />
              </div>
            </div>

            <div className="flex justify-content-between mx-2 mt-4">
              <Button

                style={{ backgroundColor: "#007bff", color: "white" }}
                onClick={handleSubmit}
              >
                SUBMIT
              </Button>

              <Button

                style={{ backgroundColor: "greenyellow", color: "black", fontWeight: '600' }}
                onClick={() => navigate("/outletProfile")}
              >
                Return to Profile
              </Button>


            </div>

            <div className="mt-3 mx-2">
              <p><span >*</span> This is for Official Usage Only</p>
            </div>




          </div>
        </Box>
      </Modal>

      <Modal
        open={setUpcompleteModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="border flex justify-content-center align-items-center border-warning rounded" sx={style2}>
          <video
            className="bgVideo"
            src={welcome_background}
            autoPlay
            muted
          />


        </Box>
      </Modal>

      <Modal
        open={storiesModal}
        // onClose={handleNightCubeSetUpClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="border border-warning rounded" sx={style} style={{ width: '1000px', position: 'relative' }}>

          <CloseIcon
            className="closeButton"
            onClick={handleClosestoriesModal}
            style={{ position: "absolute", top: "5px", right: "5px" }}
          />


          <h1 className="text-center text-light font-semibold" style={{ fontSize: '22px' }}>Add To Stories</h1>
          <div className="mt-4">

            <div className="mt-3 mx-2">
              <label
                style={{ color: "white" }}
                for="exampleFormControlInput1"
                className="form-label mb-2"
              >
                ADD PICTURE
              </label>
              <input
                type="file"
                name="image_url"
                accept=".png, .jpg, .jpeg"
                onChange={handleChangePicture}
                className="form-control"
                id="inputGroupFile04"
                aria-describedby="inputGroupFileAddon04"
                aria-label="Upload"
              />
            </div>


            <div className="mt-3 mx-2">
              <label
                style={{ color: "white" }}
                for="exampleFormControlInput1"
                className="form-label mb-2"
              >
                BLOG
              </label>
              <textarea
                rows="10"
                maxLength={8000}
                type="text"
                name="blog"
                placeholder="Write Blog"
                onChange={(e) => setBlog(e.target.value)}
                className="form-control"

              />


            </div>





            <div className="flex  mx-2 mt-4">
              <Button

                style={{ backgroundColor: "#007bff", color: "white" }}
                onClick={handleSubmitStory}
              >
                SUBMIT
              </Button>




            </div>

          </div>
        </Box>
      </Modal>


    </div>
  );


}

export default NightcubeSystems;
