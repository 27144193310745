import React from "react";



import { useEffect, useState } from "react";

import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import TuneIcon from "@mui/icons-material/Tune";
import "../assets/SingleOutlet.css";
import "./Outlets.css";

import EditNoteIcon from "@mui/icons-material/EditNote";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { useNavigate } from "react-router-dom";
import { axiosPrivate } from "../api/axios";


import ArtistCalendar from "../components/ArtistCalendar";

import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

import CloseIcon from "@mui/icons-material/Close";
import Chip from "@mui/material/Chip";
import IconButton from "@mui/material/IconButton";

import YouTubeIcon from "@mui/icons-material/YouTube";
import SoundCloud from "../assets/socialMedia/soundCLoud.jpg";

import StarIcon from "@mui/icons-material/Star";
import Rating from "@mui/material/Rating";
import { formatUrl } from '../utils/urls';

import { BASE_URL } from "../constants";

const ArtistProfile = () => {
  const navigate = useNavigate();

  const [open, setOpen] = React.useState(false);

  const [rating, setRating] = useState(0);

  const [artistData, setArtistData] = useState({});
  const [outletData, setOutletData] = useState([]);
  const [videoUrl, setVideoUrl] = useState("");

  // resident artists saved by outlet
  const [residentOutlets, setResidentOutlets] = useState([]);
  // choosed artists
  const [selectedOutlets, setSelectedOutlets] = useState([]); // Create state for selected artists
  //availaible artist = artitstdata- booked artist - resident artist
  const [availableOutlets, setAvailableOutlets] = useState([]);

  //shown in the select field
  const [outletName, setOutletName] = React.useState([]);

  const [loading, setLoading] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [isCalendarOpen, setIsCalendarOpen] = React.useState(false);

  const handleCalendarOpen = () => {
    if (artistData.authentication_status) {
      setIsCalendarOpen(true);
    } else {
      alert(
        "This Account Has Been Limited By The System. \nPlease Contact Support Team To Resolve This."
      );
    }
  };




  const handleCalendarClose = () => setIsCalendarOpen(false);

  const handleArtistInsights = () => {
    if (artistData.authentication_status) {
      navigate("/artist-insights");
    } else {
      alert(
        "This Account Has Been Limited By The System. \nPlease Contact Support Team To Resolve This."
      );
    }
  };


  const handleAddToAlbum = () => {
    if (artistData.authentication_status) {
      navigate(
        `/gallery/artist/${artistData.artist_id}/${artistData.artist_stage_name}`
      );
    } else {
      alert(
        "This Account Has Been Limited By The System. \nPlease Contact Support Team To Resolve This."
      );
    }
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const style2 = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "800px",
    bgcolor: "rgba(0,0,0,0.5)",
    border: "1px solid yellow",
    boxShadow: 24,
    p: 4,

  };

  useEffect(() => {
    let isMounted = true;
    let artistId = localStorage.getItem("userId");

    const getArtistData = async () => {
      const response = await axiosPrivate.get(`/artists/${artistId}`, {});
      setArtistData(response.data);
      setRating(response.data.rating);

    };

    const getOutletData = async () => {
      try {
        const response = await axiosPrivate.get(`/outlets`, {
          // // signal: controller.signal
          // 'Access-Control-Allow-Origin' : true
        });
        setOutletData(response.data);
      } catch (err) {
        console.error(err);
        // navigate('/login', { state: { from: location }, replace: true });
      }
    };



    isMounted && getArtistData();
    isMounted && getOutletData();
    isMounted && getresidentOutlets();

    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    setVideoUrl(artistData?.artist_intro_video);
  }, [artistData]);

  const getresidentOutlets = async () => {
    try {
      let artistId = localStorage.getItem("userId");
      console.log("----->", artistId)
      const response = await axiosPrivate.get(
        `/artistoutletsync/getResidentOutlets/${artistId}`,
        {
          // // signal: controller.signal
          // 'Access-Control-Allow-Origin' : true
        }
      );
      setResidentOutlets(response.data);
    } catch (err) {
      console.error(err);
      // navigate('/login', { state: { from: location }, replace: true });
    }
  };

  //  artist management section

  const handleChange2 = (event, newValue) => {
    if (residentOutlets.length + newValue.length > 1) {
      alert("You Can Add Only One Resident Outlet");
      return;
    }

    const artistsWithOutletInfo = newValue.map((selectedOutlet) => ({
      artist_id: artistData.artist_id,
      artist_name: artistData.outlet_name,
      outlet_id: selectedOutlet.outlet_id,
      outlet_name: selectedOutlet.outlet_name,
    }));

    // Filter out existing artists from selectedOutlets
    const filteredSelectedOutlets = selectedOutlets.filter(
      (outlet) =>
        !artistsWithOutletInfo.some(
          (newOutlet) => newOutlet.outlet_id === outlet.outlet_id
        )
    );

    // Concatenate the filtered array with the new artists
    setSelectedOutlets([...filteredSelectedOutlets, ...artistsWithOutletInfo]);

    if (Array.isArray(newValue)) {
      setOutletName(newValue);
    }
  };

  const handleRemoveOutlets = (outletToRemove) => {
    // Filter based on artist_id in selectedOutlets
    const updatedSelectedOutlets = selectedOutlets.filter(
      (outlet) => outlet.outlet_id !== outletToRemove.outlet_id
    );
    setSelectedOutlets(updatedSelectedOutlets);

    // Filter based on artist_id in outletName
    const updatedoutletName = outletName.filter(
      (outlet) => outlet.outlet_id !== outletToRemove.outlet_id
    );
    setOutletName(updatedoutletName);
  };

  const deleteresidentOutlets = async (outletId) => {
    try {
      let artistId = localStorage.getItem("userId");
      const response = await axiosPrivate.delete(
        `/artistoutletsync/deleteLinkage/${artistId}/${outletId}`,
        {}
      );
    } catch (err) {
      console.error(err);
      // navigate('/login', { state: { from: location }, replace: true });
    } finally {
      getresidentOutlets();
    }
  };

  const postresidentOutlets = async () => {
    try {
      if (residentOutlets.length >= 3) {
        alert("You Can Not Add More Than 3");
      } else {
        if (selectedOutlets.length > 0) {
          setLoading(true);

          const response = await axiosPrivate.post(
            `/artistoutletsync`,
            selectedOutlets,
            {
              "Content-Type": "application/json",
            }
          );

          setSelectedOutlets([]);
          setOutletName([]);

          alert("Resident Updated Succesfully");
        }
      }
    } catch (err) {
      console.error(err);
      // navigate('/login', { state: { from: location }, replace: true });
    } finally {
      getresidentOutlets();
      setLoading(false); // Set loading back to false when the request completes
    }
  };

  useEffect(() => {
    let isMounted = true;

    const getAvailaibleOutlets = async () => {
      try {
        // get booked artist
        const response = await axiosPrivate.get(
          `/artistoutletsync/getLinkedOutlets`,
          {
            // // signal: controller.signal
            // 'Access-Control-Allow-Origin' : true
          }
        );

        let bookedOutlets = response.data;

        let availaible_outlets = outletData.filter(
          (outlet) =>
            !bookedOutlets.some(
              (booked) => booked.outlet_id === outlet.outlet_id
            )
        );

        let availaible_outlets_without_residents = availaible_outlets.filter(
          (outlet) =>
            !residentOutlets.some(
              (residents) => residents.outlet_id === outlet.outlet_id
            )
        );

        // const availaible_artists = bookedArtist.filter((artist1) => !artistData.some((artist2) => artist1.artist_id === artist2.artist_id));

        setAvailableOutlets(availaible_outlets_without_residents);

        // setAvailableOutlets()
      } catch (err) {
        console.error(err);
        // navigate('/login', { state: { from: location }, replace: true });
      }
    };

    isMounted && getAvailaibleOutlets();

    return () => {
      isMounted = false;
    };
  }, [outletData, residentOutlets]);

  return (
    <div className="layout mb-5">
      <div className="outletparent d-flex flex-wrap mb-5 eachartistparent">
        <div className="eachartistmainsection">
          <div className="mainsection text-light d-flex flex-column mb-5">
            <div className="videosection position-relative overflow-hidden ">
              {videoUrl ? (
                <video
                  autoPlay
                  muted
                  loop
                  className="w-full h-full object-cover max-h-100  z-[-1] background-video2"
                  style={{ width: "100%" }}
                  key="new-video" >
                  <source src={`${BASE_URL}${videoUrl}`} type="video/mp4" />
                </video>
              ) : (
                <p>No video available.</p>
              )}
            </div>

            <div className="bottom-section mb-5 profile_block">
            
            <div className="artistpicturebottom ">
              <img src={`${BASE_URL}${artistData?.artist_profile_icon}`}
                alt="some outlet"
                className="avatarimg"
                style={{ objectFit: "cover" }} />
              <div className="border mt-2 border-warning p-2 rounded" >
                <h2 className="text-center" style={{ color: "white" }}>
                  {artistData?.artist_stage_name}
                </h2>
              </div>
            </div>
            <div className="artiststars mt-3">
              <div className="flex flex-column align-items-center ">
                <Rating
                  className="mb-3 border-light"
                  color="red"
                  name="half-rating"
                  size="large"
                  precision={0.5}
                  value={rating}
                  readOnly
                  emptyIcon={
                    <StarIcon
                      style={{ opacity: 0.2, color: "white" }}
                      fontSize="inherit"
                    />
                  }
                />
              </div>
            </div>
          </div>

            

            <div className="singleevent-second-banner mt-10">
              <div className="blocks mb-2">
                <div className=" blocks-content">
                  {" "}
                  <h1
                    className="text-center h5 mb-4 text-light font-semibold"
                    style={{ fontSize: "17px" }}
                  >
                    INSIGHTS
                  </h1>

                  <div>

                    <div

                      onClick={handleArtistInsights}
                      className="flex border rounded justify-content-around align-items-center cursor-pointer"
                      style={{
                        height: "auto",
                        width: "85px",
                        position: "relative",
                        top: "-50px",
                        right: "0px",
                        float: "right"
                      }}
                    >
                      <button style={{ color: "white" }}>EDIT</button>
                      <EditNoteIcon style={{ color: "white" }} />
                    </div>

                    <div
                      className="  flex flex-wrap align-items-center"
                      style={{ height: "auto", padding: "10px 0px" }}
                    >
                      <h5
                        className=" text-light font-semibold"
                        style={{ minWidth: "150px" }}
                      >
                        CATEGORY :
                      </h5>
                      <h5 className=" text-light ">
                        {artistData?.artist_category}
                      </h5>
                    </div>

                    <div
                      className=" flex align-items-center "
                      style={{ height: "auto", padding: "10px 0px" }}
                    >
                      <h5
                        className=" text-light font-semibold"
                        style={{ minWidth: "150px" }}
                      >
                        MUSIC OPERATION:
                      </h5>
                      <h5 className=" text-light ">
                        {artistData?.music_operations}
                      </h5>
                    </div>

                    <div
                      className=" flex align-items-center "
                      style={{ height: "auto", padding: "10px 0px" }}
                    >
                      <h5
                        className=" text-light font-semibold"
                        style={{ minWidth: "150px" }}
                      >
                        WEBSITE :
                      </h5>
                      <h5 className=" text-light ">
                        {artistData?.website ? (
                          <a href={formatUrl(artistData.website)} target="_blank">{artistData.website}</a>
                        ) : (
                          <span>No website available</span> // You can change this to whatever fallback you prefer
                        )}
                      </h5>
                    </div>


                    <div
                      className=" flex align-items-center "
                      style={{ height: "auto", padding: "10px 0px" }}
                    >
                      <h5
                        className=" text-light font-semibold"
                        style={{ minWidth: "150px" }}
                      >
                        RESIDENT OUTLETS :
                      </h5>
                      <div className="flex align-items-center  w-100">
                        {residentOutlets?.map((outlet, index) => (
                          <div
                            onClick={() =>
                              navigate(
                                `/singleoutlet/${outlet.outlet_id}/${outlet.outlet_name.replace(/ /g, "_")}`
                              )
                            }
                            key={index}
                            className=" p-2 border rounded me-3 cursor-pointer"
                          >
                            <p className="text-warning font-semibold">
                              {outlet.outlet_name}
                            </p>
                          </div>
                        ))}
                        <TuneIcon
                          onClick={handleOpen}
                          className="ms-3  cursor-pointer"
                          style={{ color: "white" }}
                        ></TuneIcon>
                      </div>
                    </div>

                    <div className="mt-4  flex justify-evenly">
                      {artistData.facebook_url && (
                        <FacebookIcon
                          onClick={() =>
                            window.open(artistData.facebook_url, "_blank")
                          }
                          style={{ color: "white", cursor: "pointer" }}
                        />
                      )}

                      {artistData.instragram_url && (
                        <InstagramIcon
                          onClick={() =>
                            window.open(artistData.instragram_url, "_blank")
                          }
                          style={{ color: "white", cursor: "pointer" }}
                        />
                      )}

                      {artistData.soundcloud_url && (
                        <img
                          alt="img"
                          onClick={() =>
                            window.open(artistData.soundcloud_url, "_blank")
                          }
                          src={SoundCloud}
                          style={{
                            height: "22px",
                            width: "22px",
                            objectFit: "cover",
                            cursor: "pointer",
                          }}
                        />
                      )}

                      {artistData.youtube_url && (
                        <YouTubeIcon
                          onClick={() =>
                            window.open(artistData.youtube_url, "_blank")
                          }
                          style={{ color: "white", cursor: "pointer" }}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="blocks mb-2">
                <div
                  style={{
                    backgroundColor: "rgb(186 186 186 / 6%)",
                    height: "100%",
                    width: "100%",
                    padding: "25px"

                  }}
                >
                  <h1
                    className="text-center h5 mb-4 text-light font-semibold"
                    style={{ fontSize: "17px" }}
                  >
                    SHOWS
                  </h1>
                  <div>
                    <div
                      onClick={() =>
                        navigate("/allevents/upcoming_events", {
                          state: { artistId: artistData.artist_id },
                        })
                      }
                      className="d-flex justify-content-start align-items-center mb-3"
                    >
                      <h5 className="card-title text-light cursor-pointer font-semibold mt-3">
                        UPCOMING SHOWS
                      </h5>
                    </div>

                    <div
                      onClick={() =>
                        navigate("/allevents/live_events", {
                          state: { artistId: artistData.artist_id },
                        })
                      }
                      className="d-flex justify-content-start align-items-center mb-3"
                    >
                      <h5 className="card-title text-light cursor-pointer font-semibold">
                        LIVE SHOWS
                      </h5>
                    </div>

                    <div
                      onClick={() =>
                        navigate("/allevents/previous_events", {
                          state: { artistId: artistData.artist_id },
                        })
                      }
                      className="d-flex justify-content-start align-items-center mb-3"
                    >
                      <h5 className="card-title text-light cursor-pointer font-semibold">
                        PREVIOUS SHOWS
                      </h5>
                    </div>

                    <div
                      onClick={handleCalendarOpen}
                      className="d-flex justify-content-start align-items-center mb-3"
                    >
                      <h5 className="card-title text-light cursor-pointer font-semibold">
                        CALENDER
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
              <div className="blocks mb-2">
                <div className=" blocks-content">
                  <h1
                    className="insitetext text-center pe-5 ps-5 text-light font-semibold"
                    style={{ fontSize: "17px" }}
                  >
                    GALLERY
                  </h1>

                  <div className="">
                    <div
                      onClick={handleAddToAlbum}
                      className="d-flex justify-content-start align-items-center mb-3"
                    >
                      <h5 className="card-title text-light cursor-pointer font-semibold mt-3">
                        ADD TO ALBUM
                      </h5>
                    </div>

                    <div
                      onClick={() =>
                        navigate(
                          `/images/artists/${artistData.artist_id}/${artistData.artist_stage_name}`
                        )
                      }
                      className="d-flex justify-content-start align-items-center mb-3"
                    >
                      <h5 className="card-title text-light cursor-pointer font-semibold ">
                        POTRAITS
                      </h5>
                    </div>

                    <div
                      onClick={() =>
                        navigate(
                          `/videos/show_videos/${artistData.artist_id}/${artistData.artist_stage_name}`
                        )
                      }
                      className="d-flex justify-content-start align-items-center mb-3"
                    >
                      <h5 className="card-title text-light cursor-pointer font-semibold">
                        SNIPPETS
                      </h5>
                    </div>

                  </div>
                </div>
              </div>
            </div>

            <div className=" mt-10">
              <div
                className="card  blocks-content">
                <div className="card-body p-0">
                  <h5
                    className="card-title  text-light mb-3 font-semibold"
                    style={{ fontSize: "17px" }}
                  >
                    ABOUT:
                  </h5>
                  <p
                    className="card-text text-light mb-2"
                  // style={{ whiteSpace: "pre" }}
                  >
                    {outletData?.description}
                  </p>
                </div>
              </div>
            </div>

            <div className="videosectiondisclaimer2 mb-5 mt-10">
              <div className="card  blocks-content" >
                <div className="card-body p-0">
                  <h5 className="card-title  text-light mb-3 font-semibold" style={{ fontSize: "17px" }} >
                    DISCLAIMER
                  </h5>

                  <p className="text-light" style={{ whiteSpace: "pre" }}>
                    {outletData.outlet_profile_rules_and_regulation}
                  </p>
                </div>
              </div>
            </div>
          </div>

          
        </div>
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description" >

        <Box sx={style} style={{ position: "relative" }}>
          <CloseIcon
            className="closeButton"
            onClick={handleClose}
            style={{ position: "absolute", top: "5px", right: "5px" }}
          />

          <div className="mt-3 mx-2 ">
            <label
              style={{ color: "black" }}
              for="artist-section"
              className="form-label mb-2"
            >
              SELECT OUTLETS
            </label>
            <Autocomplete
              className="rounded"
              multiple
              id="artist-section"
              style={{ backgroundColor: "white" }}
              options={availableOutlets}
              value={outletName}
              onChange={handleChange2}
              isOptionEqualToValue={(option, value) =>
                option.outlet_id === value.outlet_id
              }
              getOptionLabel={(option) => option.outlet_name} // Specify how to display the option label
              isOptionDisabled={(option) =>
                residentOutlets?.some(
                  (residentoutlet) =>
                    residentoutlet.outlet_id === option.outlet_id
                )
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder="Search..."
                  style={{ color: "white" }}
                />
              )}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    key={index}
                    label={option.outlet_name}
                    {...getTagProps({ index })}
                    onDelete={() => handleRemoveOutlets(option)}
                    deleteIcon={
                      <IconButton size="small" color="error">
                        <CloseIcon />
                      </IconButton>
                    }
                  />
                ))
              }
            />
          </div>

          <h5 className=" text-dark ms-2 mt-4 mb-2" style={{ width: "150px" }}>
            RESIDENT OUTLETS :
          </h5>

          <div className="d-flex align-items-center justify-content-around mb-3 ms-2">
            {loading ? (
              <p>Loading...</p>
            ) : residentOutlets ? (
              residentOutlets?.map((outlet, index) => (
                <div
                  className="border flex justify-between  p-2 rounded me-1"
                  style={{ width: "100px" }}
                >
                  <p className="text-dark">{outlet.outlet_name}</p>
                  <CloseIcon
                    className="cursor-pointer"
                    onClick={() => deleteresidentOutlets(outlet.outlet_id)}
                  />
                </div>
              ))
            ) : null}
          </div>

          <div className="flex justify-content-end pe-1">
            <Button
              onClick={postresidentOutlets}
              style={{ backgroundColor: "#007bff", color: "white" }}
            >
              UPDATE
            </Button>
          </div>
        </Box>
      </Modal>

      <Modal
        open={isCalendarOpen}
        onClose={handleCalendarClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style2}>
          <ArtistCalendar
            artistId={artistData.artist_id}
            artistName={artistData.artist_stage_name}
          />
        </Box>
      </Modal>

      {/* <div className="footer-overlay">
        <Footer />
      </div> */}
    </div>
  );
};

export default ArtistProfile;
