import { BarChart } from "@mui/x-charts/BarChart";
import { PieChart } from "@mui/x-charts/PieChart";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./NightCubeSystems.css";

import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";

import { axiosPrivate } from "../api/axios";


import useAuth from "../hooks/useAuth";





const LinkInput = ({ value, onChange, onBlur }) => {
  const [link, setLink] = useState(value);


  const handleChange = (e) => {
    setLink(e.target.value);
    onChange(e.target.value);
  };

  return (
    <input
      type="text"
      value={link}
      onChange={handleChange}
      onBlur={onBlur}
      placeholder="Add Link"
      style={{ width: "100%", height: "35px", color: "black", padding: "3px" }}
    />
  );
};

const CustomerMangement = () => {
  const navigate = useNavigate();
  const [expanded, setExpanded] = useState(false);
  const { auth } = useAuth();



  const location = useLocation();
  const { userRole } = location.state;



  const [updatedData, setUpdatedData] = useState([]);

  const [genderData, setGenderData] = useState([]);
  const [ageGroupData, setageGroupData] = useState([]);
  const [outingFrequencyData, setoutingFrequencyData] = useState([]);
  const [comunicationPreferenceData, setcomunicationPreferenceData] = useState(
    []
  );
  const [eventPreferenceData, seteventPreferenceData] = useState([]);
  const [musicPreferenceData, setmusicPreferenceData] = useState([]);
  const [musicPlatformData, setmusicPlatformData] = useState([]);

  const [facebookCtr, setFacebookCtr] = useState("");
  const [facebookBudget, setFacebookBudget] = useState("");
  const [facebookConversion, setFacebookConversion] = useState("");

  const handleUpdateClick = () => {
    const updatedValues = rows.map((row) => ({
      id: row.id,
      link: row.link,
    }));
    setUpdatedData(updatedValues);

  };

  useEffect(() => {
    let isMounted = true;


    const getUserbyCategory = async (category) => {
      try {
        const response = await axiosPrivate.get(
          `/customers/category/${category}`,
          {
            // // signal: controller.signal
            // 'Access-Control-Allow-Origin' : true
          }
        );

        category === "gender"
          ? setGenderData(response.data)
          : category === "age_group"
            ? setageGroupData(response.data)
            : category === "outing_frequency"
              ? setoutingFrequencyData(response.data)
              : category === "music_platform"
                ? setmusicPlatformData(response.data)
                : category === "music_preference"
                  ? setmusicPreferenceData(response.data)
                  : category === "event_preference"
                    ? seteventPreferenceData(response.data)
                    : setcomunicationPreferenceData(response.data);
        console.log("music pref->", response.data)
      } catch (err) {
        console.error(err);
      }
    };

    const getUserbyCategorySurvey = async (category) => {
      try {
        let outletId = localStorage.getItem("userId");
        const response = await axiosPrivate.get(
          `/customers/survey/${category}/${outletId}`,
          {
            // // signal: controller.signal
            // 'Access-Control-Allow-Origin' : true
          }
        );

        category === "gender"
          ? setGenderData(response.data)
          : category === "age_group"
            ? setageGroupData(response.data)
            : category === "outing_frequency"
              ? setoutingFrequencyData(response.data)
              : category === "music_platform"
                ? setmusicPlatformData(response.data)
                : category === "music_preference"
                  ? setmusicPreferenceData(response.data)
                  : category === "event_preference"
                    ? seteventPreferenceData(response.data)
                    : setcomunicationPreferenceData(response.data);
      } catch (err) {
        console.error(err);
      }
    };

    if (userRole === 'outlet') {
      isMounted && getUserbyCategorySurvey("gender");
      isMounted && getUserbyCategorySurvey("age_group");
      isMounted && getUserbyCategorySurvey("outing_frequency");
      isMounted && getUserbyCategorySurvey("music_platform");
      isMounted && getUserbyCategorySurvey("music_preference");
      isMounted && getUserbyCategorySurvey("event_preference");
      isMounted && getUserbyCategorySurvey("communication_preference");
    } else {
      isMounted && getUserbyCategory("gender");
      isMounted && getUserbyCategory("age_group");
      isMounted && getUserbyCategory("outing_frequency");
      isMounted && getUserbyCategory("music_platform");
      isMounted && getUserbyCategory("music_preference");
      isMounted && getUserbyCategory("event_preference");
      isMounted && getUserbyCategory("communication_preference");
    }



    return () => {
      isMounted = false;
    };


  }, [userRole]);

  const columns = [
    { field: "id", headerName: "SL.NO.", width: 90 },
    {
      field: "platform",
      headerName: "PLATFORM",
      width: 150,
      editable: true,
    },
    {
      field: "link",
      headerName: "LINK",
      width: 150,
      editable: true,
      renderCell: (params) => {
        return (
          <LinkInput
            value={params.value}
            onChange={(newValue) => {
              // Implement your logic to update the link value here

            }}
            onBlur={() => {
              // Handle onBlur if needed
            }}
          />
        );
      },
    },
  ];

  const rows = [
    { id: 1, platform: "FACEBOOK", link: "" },
    { id: 2, platform: "INSTAGRAM", link: "" },
    { id: 3, platform: "GOOGLE", link: "" },
    { id: 4, platform: "YOUTUBE", link: "" },
    { id: 5, platform: "WHATSAPP", link: "" },
  ];

  const CustomPagination = () => (
    <div>
      <button onClick={handleUpdateClick}>Update</button>
    </div>
  );



  return (
    <div className="parent ">
      <div className="mainsection">
        <div className="container-fluid text-center">
          <div className="row ">

            <div
              className="col  d-flex flex-column justify-content-start align-items-center "
              style={{ height: "340px", width: "25%" }}
            >
              <div
                className="maincircle flex flex-column justify-content-center align-items-center mx-0"
                style={{ height: "100%", width: "100%" }}
              >
                <div
                  className="piechart d-flex  justify-content-start align-items-start  custom-pie-chart-container"
                  style={{ width: "100%", borderRight: "1px solid #FFD700" }}
                >
                  <PieChart
                    className="border"
                    series={[
                      {
                        data: genderData.map((item, index) => ({
                          id: index,
                          value: item.count,
                          label: item.category.toUpperCase(),
                          color:
                            item.category === "Male" ? "#00FC7E" : "#00EBFA",
                        })),
                      },
                    ]}
                    width={500}
                    height={250}
                    margin={{ right: 300 }}
                  />
                </div>

                <h4 className="text-center mt-5 font-semibold ">
                  GENDER RATIO
                </h4>
              </div>
            </div>

            <div
              className="col  d-flex flex-column justify-content-start align-items-center"
              style={{ height: "340px", width: "25%" }}
            >
              <div
                className="maincircle flex flex-column justify-content-center align-items-center mx-0"
                style={{ height: "100%", width: "100%" }}
              >
                <div
                  className="piechart d-flex  justify-content-start align-items-start  custom-pie-chart-container"
                  style={{ width: "100%", borderRight: "1px solid #FFD700" }}
                >
                  <PieChart
                    series={[
                      {
                        data: ageGroupData.map((item, index) => ({
                          id: index,
                          value: item.count,
                          label: item.category.toUpperCase(),
                          color:
                            item.category === "16-20"
                              ? "#00FC7E"
                              : item.category === "21-25"
                                ? "#00EBFA"
                                : item.category === "26-30"
                                  ? "#FAF700"
                                  : item.category === "31-35"
                                    ? "#FFFFFF"
                                    : item.category === "36-40"
                                      ? "#FF3600"
                                      : "#FE0041",
                        })),
                      },
                    ]}
                    width={500}
                    height={250}
                    margin={{ right: 300 }}
                  />
                </div>

                <h4 className="text-center mt-5 font-semibold">AGE GROUP</h4>
              </div>
            </div>

            <div
              className="col  d-flex flex-column justify-content-start align-items-center"
              style={{ height: "340px", width: "25%" }}
            >
              <div
                className="maincircle flex flex-column justify-content-center align-items-center mx-0"
                style={{ height: "100%", width: "100%" }}
              >
                <div
                  className="piechart d-flex  justify-content-start align-items-start  custom-pie-chart-container"
                  style={{ width: "100%", borderRight: "1px solid #FFD700" }}
                >
                  <PieChart
                    series={[
                      {
                        data: outingFrequencyData.map((item, index) => ({
                          id: index,
                          value: item.count,
                          label: item.category.toUpperCase(),
                          color:
                            item.category === "ONCE EVERY WEEK"
                              ? "#FE0041"
                              : item.category === "ONCE EVERY TWO WEEK"
                                ? "#FAF700"
                                : "#00FC7E",
                        })),
                      },
                    ]}
                    width={550}
                    height={250}
                    margin={{ right: 300 }}
                  />
                </div>

                <h4 className="text-center mt-5 font-semibold">
                  OUTING FREQUENCY
                </h4>
              </div>
            </div>

            <div
              className="col  d-flex flex-column justify-content-start align-items-center"
              style={{ height: "340px", width: "25%" }}
            >
              <div
                className="maincircle flex flex-column justify-content-center align-items-center mx-0"
                style={{ height: "100%", width: "100%" }}
              >
                <div
                  className="piechart d-flex  justify-content-start align-items-start  custom-pie-chart-container"
                  style={{ width: "100%" }}
                >
                  <PieChart
                    series={[
                      {
                        data: comunicationPreferenceData.map((item, index) => ({
                          id: index,
                          value: item.count,
                          label: item.category.toUpperCase(),
                          color:
                            item.category === "WHATSAPP"
                              ? "#00FC7E"
                              : item.category === "EMAIL"
                                ? "#FAF700"
                                : "#FE0041",
                        })),
                      },
                    ]}
                    width={500}
                    height={250}
                    margin={{ right: 300 }}
                  />
                </div>

                <h4 className="text-center mt-5 font-semibold">
                  COMMUNICATION CHANNELS
                </h4>
              </div>
            </div>


          </div>


          {userRole !== 'admin1' &&
            <div className="row ">
              <div
                className="col  d-flex flex-column justify-content-start align-items-center "
                style={{ height: "340px", width: "33%" }}
              >
                <div
                  className="maincircle flex flex-column justify-content-center align-items-center mx-0"
                  style={{ height: "100%", width: "100%" }}
                >
                  <div
                    className="piechart d-flex justify-content-start align-items-center custom-pie-chart-container"
                    style={{ width: "100%", borderRight: "1px solid #FFD700" }}
                  >
                    <PieChart
                      series={[
                        {
                          data: eventPreferenceData.map((item, index) => ({
                            id: index,
                            value: item.count,
                            label: item.category.toUpperCase(),
                            color:
                              item.category === "LADIES NIGHT"
                                ? "#FAF700"
                                : item.category === "BRUNCH"
                                  ? "#00EBFA"
                                  : item.category === "FRIDAY NIGHT LIVE"
                                    ? "#FF3600"
                                    : item.category === "SATURDAY NIGHT LIVE"
                                      ? "#FFFFFF"
                                      : item.category === "SUNDAY NIGHT LIVE"
                                        ? "#3396B8"
                                        : item.category === "COSTUME & THEMES"
                                          ? "#00FC7E"
                                          : item.category === "CONCEPTS"
                                            ? "#FE0041"
                                            : "#01950F",
                          })),
                        },
                      ]}
                      width={790}
                      height={250}
                      margin={{ right: 300 }}
                    />
                  </div>

                  <h4 className="text-center mt-5 font-semibold">
                    EVENT PREFERENCE
                  </h4>
                </div>
              </div>

              <div
                className="col  d-flex flex-column justify-content-start align-items-center "
                style={{ height: "340px", width: "33%" }}
              >
                <div
                  className="maincircle flex flex-column justify-content-center align-items-center mx-0"
                  style={{ height: "100%", width: "100%" }}
                >
                  <div
                    className="piechart d-flex justify-content-start align-items-center custom-pie-chart-container"
                    style={{ width: "100%", borderRight: "1px solid #FFD700" }}
                  >
                    <PieChart
                      series={[
                        {
                          data: musicPreferenceData.map((item, index) => ({
                            id: index,
                            value: item.count,
                            label: item.category.toUpperCase() == "BOLLYWOOD" ? "BOLLYWOOD (हिंदी)" : item.category.toUpperCase() == "KANNADA" ? "KANNADA (ಕನ್ನಡ)" : item.category.toUpperCase(),
                            color:
                              item.category === "BOLLYWOOD"
                                ? "#00FC7E"
                                : item.category === "EDM"
                                  ? "#900C3F"
                                  : item.category === "TECHNO/UNDERGROUND"
                                    ? "#3696A6"
                                    : item.category === "DUBSTEP/BASS"
                                      ? "#FF3600"
                                      : item.category === "BIG ROOM/ELECTRO"
                                        ? "#FFFFFF"
                                        : item.category === "DEEP/HOUSE"
                                          ? "#010101"
                                          : item.category === "CHILL/HOUSE"
                                            ? "#6E7C7A"
                                            : item.category === "HIP-HOP/TRAP"
                                              ? "#00EBFA"
                                              : item.category === "LIVE BAND/METAL"
                                                ? "#ED0241" : item.category == "KANNADA" ? "#FFFF00"
                                                  : "#AD5041",
                          })),
                        },
                      ]}
                      width={990}
                      height={250}
                      margin={{ right: 300 }}
                    />
                  </div>

                  <h4 className="text-center mt-5 font-semibold">
                    MUSIC PREFERENCE
                  </h4>
                </div>
              </div>

              {userRole !== 'outlet' &&
                <div
                  className="col  d-flex flex-column justify-content-start align-items-center "
                  style={{ height: "340px", width: "33%" }}
                >
                  <div
                    className="maincircle flex flex-column justify-content-center align-items-center mx-0"
                    style={{ height: "100%", width: "100%" }}
                  >
                    <div
                      className="piechart d-flex justify-content-start align-items-center custom-pie-chart-container"
                      style={{ width: "100%" }}
                    >
                      <PieChart
                        series={[
                          {
                            data: musicPlatformData.map((item, index) => ({
                              id: index,
                              value: item.count,
                              label: item.category.toUpperCase(),
                              color:
                                item.category === "APPLE ITUNES"
                                  ? "#00FC7E"
                                  : item.category === "SPOTIFY"
                                    ? "#900C3F"
                                    : item.category === "JIO SAAVAN"
                                      ? "#3696A6"
                                      : item.category === "AMAZON MUSIC"
                                        ? "#FF3600"
                                        : item.category === "SOUNDCLOUD"
                                          ? "#FFFFFF"
                                          : item.category === "YOUTUBE MUSIC"
                                            ? "#FAF700"
                                            : "#6E7C7A",
                            })),
                          },
                        ]}
                        width={790}
                        height={250}
                        margin={{ right: 300 }}
                      />
                    </div>

                    <h4 className="text-center mt-5 font-semibold">
                      RADIO PLATFORMS
                    </h4>
                  </div>
                </div>
              }





            </div>
          }



          <div className="mainsectionend ">
            <div className=" flex justify-content-end">
              <div
                className=" flex align-items-center pb-3 pt-1 ps-3 justify-content-between font-semibold"
                style={{ height: "40px", width: "250px" }}
              >
                <h1 >EXPAND DISTRIBUTION</h1>

                {expanded ? (
                  <KeyboardDoubleArrowUpIcon
                    onClick={() => setExpanded(false)}
                    className="cursor-pointer"
                    style={{ fontSize: "30" }}
                  />
                ) : (
                  <KeyboardDoubleArrowDownIcon
                    onClick={() => setExpanded(true)}
                    className="cursor-pointer "
                    style={{ fontSize: "30" }}
                  />
                )}
              </div>
            </div>

            {expanded && (
              <div>
                <div className="accordion-body">
                  <div className="row">
                    <div
                      className="col flex flex-column justify-content-center align-items-center"
                      style={{
                        height: "450px",
                        borderRight: "1px solid #FFD700",
                      }}
                    >
                      <div
                        className="maincircle d-flex  justify-content-center "
                        style={{
                          height: "80%",
                          width: "100%",
                          // borderBottom: "0.3px solid #ccc",
                        }}
                      >
                        <div className=" flex flex-column  justify-content-center align-items-center">
                          <div
                            className="piechartdiv flex  "
                            style={{ paddingLeft: "80px" }}
                          >
                            <PieChart
                              series={[
                                {
                                  data: genderData.map((item, index) => ({
                                    id: index,
                                    value: item.count,

                                    color:
                                      item.category === "Male"
                                        ? "#00FC7E"
                                        : "#00EBFA",
                                  })),
                                },
                              ]}
                              width={300}
                              height={300}
                            />
                          </div>

                          <h4 className="text-center font-semibold">
                            GENDER RATIO
                          </h4>
                        </div>

                        <div className="piecalculations d-flex align-items-center custom-pie-chart-container ">
                          <BarChart
                            xAxis={[
                              {
                                id: "barCategories",
                                data: genderData.map((item, index) =>
                                  item.category.toUpperCase()
                                ), // Assuming you want to use index as category
                                scaleType: "band",
                              },
                            ]}
                            series={[
                              {
                                data: genderData.map((item) => item.count),
                              },
                            ]}
                            width={230}
                            height={300}
                          />
                        </div>
                      </div>

                      <div
                        className=" flex align-items-center justify-content-evenly"
                        style={{ height: "20%", width: "100%" }}
                      >
                        <div className="flex align-items-center ">
                          <div
                            className=" me-2"
                            style={{
                              height: "20px",
                              width: "20px",
                              backgroundColor: "#00EBFA",
                            }}
                          ></div>
                          <h1>FEMALE</h1>
                        </div>

                        <div className="flex align-items-center ">
                          <div
                            className=" me-2"
                            style={{
                              height: "20px",
                              width: "20px",
                              backgroundColor: "#00FC7E",
                            }}
                          ></div>
                          <h1>MALE</h1>
                        </div>
                      </div>
                    </div>

                    <div
                      className="col flex flex-column justify-content-center align-items-center"
                      style={{ height: "450px" }}
                    >
                      <div
                        className="maincircle d-flex justify-content-center"
                        style={{ height: "80%", width: "100%" }}
                      >
                        <div className=" flex flex-column  justify-content-center align-items-center">
                          <div
                            className="piechartdiv flex  "
                            style={{ paddingLeft: "80px" }}
                          >
                            <PieChart
                              series={[
                                {
                                  data: ageGroupData.map((item, index) => ({
                                    id: index,
                                    value: item.count,

                                    color:
                                      item.category === "16-20"
                                        ? "#00FC7E"
                                        : item.category === "21-25"
                                          ? "#00EBFA"
                                          : item.category === "26-30"
                                            ? "#FAF700"
                                            : item.category === "31-35"
                                              ? "#FFFFFF"
                                              : item.category === "36-40"
                                                ? "#FF3600"
                                                : "#FE0041",
                                  })),
                                },
                              ]}
                              width={300}
                              height={300}
                            // margin={10}
                            />
                          </div>

                          <h4 className="text-center font-semibold">
                            AGE GROUP
                          </h4>
                        </div>

                        <div className="piecalculations d-flex align-items-center custom-pie-chart-container">
                          <BarChart
                            xAxis={[
                              {
                                id: "barCategories",
                                data: ageGroupData.map((item, index) =>
                                  item.category.toUpperCase()
                                ), // Assuming you want to use index as category
                                scaleType: "band",
                              },
                            ]}
                            series={[
                              {
                                data: ageGroupData.map((item) => item.count),
                              },
                            ]}
                            width={380}
                            height={300}
                          />
                        </div>
                      </div>

                      <div
                        className=" flex align-items-center justify-content-evenly"
                        style={{ height: "20%", width: "100%" }}
                      >
                        <div className="flex align-items-center ">
                          <div
                            className=" me-2"
                            style={{
                              height: "20px",
                              width: "20px",
                              backgroundColor: "#00FC7E",
                            }}
                          ></div>
                          <h1>16-20</h1>
                        </div>

                        <div className="flex align-items-center ">
                          <div
                            className=" me-2"
                            style={{
                              height: "20px",
                              width: "20px",
                              backgroundColor: "#00EBFA",
                            }}
                          ></div>
                          <h1>21-25</h1>
                        </div>

                        <div className="flex align-items-center ">
                          <div
                            className=" me-2"
                            style={{
                              height: "20px",
                              width: "20px",
                              backgroundColor: "#FAF700",
                            }}
                          ></div>
                          <h1>26-30</h1>
                        </div>

                        <div className="flex align-items-center ">
                          <div
                            className=" me-2"
                            style={{
                              height: "20px",
                              width: "20px",
                              backgroundColor: "#FFFFFF",
                            }}
                          ></div>
                          <h1>31-35</h1>
                        </div>

                        <div className="flex align-items-center ">
                          <div
                            className=" me-2"
                            style={{
                              height: "20px",
                              width: "20px",
                              backgroundColor: "#FF3600",
                            }}
                          ></div>
                          <h1>36-40</h1>
                        </div>

                        <div className="flex align-items-center ">
                          <div
                            className=" me-2"
                            style={{
                              height: "20px",
                              width: "20px",
                              backgroundColor: "#FE0041",
                            }}
                          ></div>
                          <h1>40+</h1>
                        </div>
                      </div>
                    </div>

                    <div
                      className="col flex flex-column justify-content-center align-items-center"
                      style={{
                        height: "450px",
                        borderRight: "1px solid #FFD700",
                      }}
                    >
                      <div
                        className="maincircle d-flex justify-content-center"
                        style={{ height: "80%", width: "100%" }}
                      >
                        <div className=" flex flex-column  justify-content-center align-items-center">
                          <div
                            className="piechartdiv flex  "
                            style={{ paddingLeft: "80px" }}
                          >
                            <PieChart
                              series={[
                                {
                                  data: outingFrequencyData.map(
                                    (item, index) => ({
                                      id: index,
                                      value: item.count,
                                      color:
                                        item.category === "ONCE EVERY WEEK"
                                          ? "#FE0041"
                                          : item.category ===
                                            "ONCE EVERY TWO WEEK"
                                            ? "#FAF700"
                                            : "#00FC7E",
                                    })
                                  ),
                                },
                              ]}
                              width={300}
                              height={300}
                            />
                          </div>

                          <h4 className="text-center font-semibold">
                            OUTING FREQUENCY
                          </h4>
                        </div>

                        <div className="piecalculations d-flex align-items-center custom-pie-chart-container">
                          <BarChart
                            xAxis={[
                              {
                                id: "barCategories",
                                data: outingFrequencyData.map((item) =>
                                  item.category === "ONCE EVERY WEEK"
                                    ? "ONCE PER WEEK"
                                    : item.category === "ONCE EVERY TWO WEEK"
                                      ? "ONCE PER TWO WEEK"
                                      : "ONCE PER MONTH"
                                ),
                                scaleType: "band",
                              },
                            ]}
                            series={[
                              {
                                data: outingFrequencyData.map(
                                  (item) => item.count
                                ),
                              },
                            ]}
                            width={500}
                            height={300}
                          />
                        </div>
                      </div>

                      <div
                        className=" flex align-items-center justify-content-evenly"
                        style={{ height: "20%", width: "100%" }}
                      >
                        <div className="flex align-items-center ">
                          <div
                            className=" me-2"
                            style={{
                              height: "20px",
                              width: "20px",
                              backgroundColor: "#FE0041",
                            }}
                          ></div>
                          <h1>ONCE PER WEEK</h1>
                        </div>

                        <div className="flex align-items-center ">
                          <div
                            className=" me-2"
                            style={{
                              height: "20px",
                              width: "20px",
                              backgroundColor: "#FAF700",
                            }}
                          ></div>
                          <h1>ONCE PER TWO WEEK</h1>
                        </div>

                        <div className="flex align-items-center ">
                          <div
                            className=" me-2"
                            style={{
                              height: "20px",
                              width: "20px",
                              backgroundColor: "#00FC7E",
                            }}
                          ></div>
                          <h1>ONCE PER MONTH</h1>
                        </div>
                      </div>
                    </div>

                    <div
                      className="col flex flex-column justify-content-center align-items-center"
                      style={{ height: "450px" }}
                    >
                      <div
                        className="maincircle d-flex justify-content-center"
                        style={{ height: "80%", width: "100%" }}
                      >
                        <div className=" flex flex-column  justify-content-center align-items-center">
                          <div
                            className="piechartdiv flex  "
                            style={{ paddingLeft: "80px" }}
                          >
                            <PieChart
                              series={[
                                {
                                  data: comunicationPreferenceData.map(
                                    (item, index) => ({
                                      id: index,
                                      value: item.count,

                                      color:
                                        item.category === "WHATSAPP"
                                          ? "#00FC7E"
                                          : item.category === "EMAIL"
                                            ? "#FAF700"
                                            : "#FE0041",
                                    })
                                  ),
                                },
                              ]}
                              width={300}
                              height={300}
                            />
                          </div>

                          <h4 className="text-center font-semibold">
                            COMMUNICATION CHANNELS
                          </h4>
                        </div>

                        <div className="piecalculations d-flex align-items-center custom-pie-chart-container">
                          <BarChart
                            xAxis={[
                              {
                                id: "barCategories",
                                data: comunicationPreferenceData.map(
                                  (item, index) => item.category.toUpperCase()
                                ), // Assuming you want to use index as category
                                scaleType: "band",
                              },
                            ]}
                            series={[
                              {
                                data: comunicationPreferenceData.map(
                                  (item) => item.count
                                ),
                              },
                            ]}
                            width={500}
                            height={300}
                          />
                        </div>
                      </div>

                      <div
                        className=" flex align-items-center justify-content-evenly"
                        style={{ height: "20%", width: "100%" }}
                      >
                        <div className="flex align-items-center ">
                          <div
                            className=" me-2"
                            style={{
                              height: "20px",
                              width: "20px",
                              backgroundColor: "#FAF700",
                            }}
                          ></div>
                          <h1>EMAIL</h1>
                        </div>

                        <div className="flex align-items-center ">
                          <div
                            className=" me-2"
                            style={{
                              height: "20px",
                              width: "20px",
                              backgroundColor: "#00FC7E",
                            }}
                          ></div>
                          <h1>WHATSAPP</h1>
                        </div>

                        <div className="flex align-items-center ">
                          <div
                            className=" me-2"
                            style={{
                              height: "20px",
                              width: "20px",
                              backgroundColor: "#FE0041",
                            }}
                          ></div>
                          <h1>MESSAGES</h1>
                        </div>
                      </div>
                    </div>



                    {userRole !== 'admin1' &&
                      <div
                        className="col flex flex-column justify-content-center align-items-center"
                        style={{
                          height: "450px",
                          borderTop: "1px solid #FFD700",
                          borderBottom: "1px solid #FFD700",
                        }}
                      >
                        <div
                          className="maincircle d-flex justify-content-center"
                          style={{ height: "100%", width: "100%" }}
                        >
                          <div className=" flex flex-column  justify-content-center align-items-center">
                            <div
                              className="piechartdiv flex  "
                              style={{ paddingLeft: "80px" }}
                            >
                              <PieChart
                                series={[
                                  {

                                    data: musicPreferenceData.map(
                                      (item, index) => ({
                                        id: index,
                                        value: item.count,

                                        color:
                                          item.category === "BOLLYWOOD"
                                            ? "#00FC7E"
                                            : item.category === "EDM"
                                              ? "#900C3F"
                                              : item.category ===
                                                "TECHNO/UNDERGROUND"
                                                ? "#3696A6"
                                                : item.category === "DUBSTEP/BASS"
                                                  ? "#FF3600"
                                                  : item.category === "BIG ROOM/ELECTRO"
                                                    ? "#FFFFFF"
                                                    : item.category === "DEEP/HOUSE"
                                                      ? "#010101"
                                                      : item.category === "CHILL/HOUSE"
                                                        ? "#6E7C7A"
                                                        : item.category === "HIP-HOP/TRAP"
                                                          ? "#00EBFA"
                                                          : item.category === "LIVE BAND/METAL"
                                                            ? "#ED0241" : item.category == "KANNADA" ? "#FFFF00"
                                                              : "#AD5041",
                                      })
                                    ),
                                  },
                                ]}
                                width={300}
                                height={300}
                              />
                            </div>

                            <h4 className="text-center font-semibold">
                              MUSIC PREFERENCE
                            </h4>
                          </div>

                          <div className="piecalculations d-flex align-items-center custom-pie-chart-container">
                            <BarChart
                              xAxis={[
                                {
                                  id: "barCategories",
                                  data: musicPreferenceData.map((item, index) =>
                                    item.category.toUpperCase()
                                  ), // Assuming you want to use index as category
                                  scaleType: "band",
                                },
                              ]}
                              series={[
                                {
                                  data: musicPreferenceData.map(
                                    (item) => item.count
                                  ),
                                },
                              ]}
                              width={1200}
                              height={300}
                            />
                          </div>
                        </div>

                        <div
                          className=" flex align-items-center justify-content-evenly"
                          style={{ height: "20%", width: "100%" }}
                        >
                          <div className="flex align-items-center ">
                            <div
                              className=" me-2"
                              style={{
                                height: "20px",
                                width: "20px",
                                backgroundColor: "#00FC7E",
                              }}
                            ></div>
                            <h1>BOLLYWOOD (हिंदी)</h1>
                          </div>

                          <div className="flex align-items-center ">
                            <div
                              className=" me-2"
                              style={{
                                height: "20px",
                                width: "20px",
                                backgroundColor: "#900C3F",
                              }}
                            ></div>
                            <h1>EDM</h1>
                          </div>

                          <div className="flex align-items-center ">
                            <div
                              className=" me-2"
                              style={{
                                height: "20px",
                                width: "20px",
                                backgroundColor: "#3696A6",
                              }}
                            ></div>
                            <h1>TECHNO/UNDERGROUND</h1>
                          </div>

                          <div className="flex align-items-center ">
                            <div
                              className=" me-2"
                              style={{
                                height: "20px",
                                width: "20px",
                                backgroundColor: "#FF3600",
                              }}
                            ></div>
                            <h1>DUBSTEP/BASS</h1>
                          </div>

                          <div className="flex align-items-center ">
                            <div
                              className=" me-2"
                              style={{
                                height: "20px",
                                width: "20px",
                                backgroundColor: "#FFFFFF",
                              }}
                            ></div>
                            <h1>BIG ROOM/ELECTRO</h1>
                          </div>

                          <div className="flex align-items-center ">
                            <div
                              className=" me-2"
                              style={{
                                height: "20px",
                                width: "20px",
                                backgroundColor: "#010101",
                              }}
                            ></div>
                            <h1>DEEP/HOUSE</h1>
                          </div>

                          <div className="flex align-items-center ">
                            <div
                              className=" me-2"
                              style={{
                                height: "20px",
                                width: "20px",
                                backgroundColor: "#6E7C7A",
                              }}
                            ></div>
                            <h1>CHILL/HOUSE</h1>
                          </div>

                          <div className="flex align-items-center ">
                            <div
                              className=" me-2"
                              style={{
                                height: "20px",
                                width: "20px",
                                backgroundColor: "#00EBFA",
                              }}
                            ></div>
                            <h1>HIP-HOP/TRAP</h1>
                          </div>

                          <div className="flex align-items-center ">
                            <div
                              className=" me-2"
                              style={{
                                height: "20px",
                                width: "20px",
                                backgroundColor: "#ED0241",
                              }}
                            ></div>
                            <h1>LIVE BAND/METAL</h1>
                          </div>

                          <div className="flex align-items-center ">
                            <div
                              className=" me-2"
                              style={{
                                height: "20px",
                                width: "20px",
                                backgroundColor: "#FFFF00",
                              }}
                            ></div>
                            <h1>KANNADA (ಕನ್ನಡ)</h1>
                          </div>
                        </div>
                      </div>
                    }

                    {userRole !== 'admin1' &&
                      <div
                        className="col flex flex-column justify-content-center align-items-center"
                        style={{
                          height: "450px",
                          borderBottom: "1px solid #FFD700",
                        }}
                      >
                        <div
                          className="maincircle d-flex justify-content-center"
                          style={{ height: "80%", width: "100%" }}
                        >
                          <div className=" flex flex-column  justify-content-center align-items-center">
                            <div
                              className="piechartdiv flex  "
                              style={{ paddingLeft: "80px" }}
                            >
                              <PieChart
                                series={[
                                  {
                                    data: eventPreferenceData.map(
                                      (item, index) => ({
                                        id: index,
                                        value: item.count,

                                        color:
                                          item.category === "LADIES NIGHT"
                                            ? "#FAF700"
                                            : item.category === "BRUNCH"
                                              ? "#00EBFA"
                                              : item.category ===
                                                "FRIDAY NIGHT LIVE"
                                                ? "#FF3600"
                                                : item.category ===
                                                  "SATURDAY NIGHT LIVE"
                                                  ? "#FFFFFF"
                                                  : item.category ===
                                                    "SUNDAY NIGHT LIVE"
                                                    ? "#3396B8"
                                                    : item.category === "COSTUME & THEMES"
                                                      ? "#00FC7E"
                                                      : item.category === "CONCEPTS"
                                                        ? "#FE0041"
                                                        : "#01950F",
                                      })
                                    ),
                                  },
                                ]}
                                width={300}
                                height={300}
                              />
                            </div>

                            <h4 className="text-center font-semibold">
                              EVENT PREFERENCE
                            </h4>
                          </div>

                          <div className="piecalculations d-flex align-items-center custom-pie-chart-container">
                            <BarChart
                              xAxis={[
                                {
                                  id: "barCategories",
                                  data: eventPreferenceData.map((item, index) =>
                                    item.category.toUpperCase()
                                  ), // Assuming you want to use index as category
                                  scaleType: "band",
                                },
                              ]}
                              series={[
                                {
                                  data: eventPreferenceData.map(
                                    (item) => item.count
                                  ),
                                },
                              ]}
                              width={1200}
                              height={300}
                            />
                          </div>
                        </div>

                        <div
                          className=" flex align-items-center justify-content-evenly"
                          style={{ height: "20%", width: "100%" }}
                        >
                          <div className="flex align-items-center ">
                            <div
                              className=" me-2"
                              style={{
                                height: "20px",
                                width: "20px",
                                backgroundColor: "#FAF700",
                              }}
                            ></div>
                            <h1>LADIES NIGHT</h1>
                          </div>

                          <div className="flex align-items-center ">
                            <div
                              className=" me-2"
                              style={{
                                height: "20px",
                                width: "20px",
                                backgroundColor: "#00EBFA",
                              }}
                            ></div>
                            <h1>BRUNCH</h1>
                          </div>

                          <div className="flex align-items-center ">
                            <div
                              className=" me-2"
                              style={{
                                height: "20px",
                                width: "20px",
                                backgroundColor: "#FF3600",
                              }}
                            ></div>
                            <h1>FRIDAY NIGHT LIVE</h1>
                          </div>

                          <div className="flex align-items-center ">
                            <div
                              className=" me-2"
                              style={{
                                height: "20px",
                                width: "20px",
                                backgroundColor: "#FFFFFF",
                              }}
                            ></div>
                            <h1>SATURDAY NIGHT LIVE</h1>
                          </div>

                          <div className="flex align-items-center ">
                            <div
                              className=" me-2"
                              style={{
                                height: "20px",
                                width: "20px",
                                backgroundColor: "#3396B8",
                              }}
                            ></div>
                            <h1>SUNDAY NIGHT LIVE</h1>
                          </div>

                          <div className="flex align-items-center ">
                            <div
                              className=" me-2"
                              style={{
                                height: "20px",
                                width: "20px",
                                backgroundColor: "#00FC7E",
                              }}
                            ></div>
                            <h1>COSTUME & THEMES</h1>
                          </div>

                          <div className="flex align-items-center ">
                            <div
                              className=" me-2"
                              style={{
                                height: "20px",
                                width: "20px",
                                backgroundColor: "#FE0041",
                              }}
                            ></div>
                            <h1>CONCEPTS</h1>
                          </div>

                          <div className="flex align-items-center ">
                            <div
                              className=" me-2"
                              style={{
                                height: "20px",
                                width: "20px",
                                backgroundColor: "#01950F",
                              }}
                            ></div>
                            <h1>HAPPY HOURS</h1>
                          </div>
                        </div>
                      </div>
                    }



                    {(userRole !== 'admin1' && userRole !== 'outlet') &&
                      <div
                        className="col flex flex-column justify-content-center align-items-center"
                        style={{
                          height: "450px",
                          borderBottom: "1px solid #FFD700",
                        }}
                      >
                        <div
                          className="maincircle d-flex justify-content-center"
                          style={{ height: "80%", width: "100%" }}
                        >
                          <div className=" flex flex-column  justify-content-center align-items-center">
                            <div
                              className="piechartdiv flex  "
                              style={{ paddingLeft: "80px" }}
                            >
                              <PieChart
                                series={[
                                  {
                                    data: musicPlatformData.map(
                                      (item, index) => ({
                                        id: index,
                                        value: item.count,
                                        color:
                                          item.category === "APPLE ITUNES"
                                            ? "#00FC7E"
                                            : item.category === "SPOTIFY"
                                              ? "#900C3F"
                                              : item.category === "JIO SAAVAN"
                                                ? "#3696A6"
                                                : item.category === "AMAZON MUSIC"
                                                  ? "#FF3600"
                                                  : item.category === "SOUNDCLOUD"
                                                    ? "#FFFFFF"
                                                    : item.category === "YOUTUBE MUSIC"
                                                      ? "#FAF700"
                                                      : "#6E7C7A",
                                      })
                                    ),
                                  },
                                ]}
                                width={300}
                                height={300}
                              />
                            </div>

                            <h4 className="text-center font-semibold">
                              RADIO PLATFORMS
                            </h4>
                          </div>

                          <div className="piecalculations d-flex align-items-center custom-pie-chart-container">
                            <BarChart
                              xAxis={[
                                {
                                  id: "barCategories",
                                  data: musicPlatformData.map((item, index) =>
                                    item.category.toUpperCase()
                                  ), // Assuming you want to use index as category
                                  scaleType: "band",
                                },
                              ]}
                              series={[
                                {
                                  data: musicPlatformData.map(
                                    (item) => item.count
                                  ),
                                },
                              ]}
                              width={1200}
                              height={300}
                            />
                          </div>
                        </div>

                        <div
                          className=" flex align-items-center justify-content-evenly"
                          style={{ height: "20%", width: "100%" }}
                        >
                          <div className="flex align-items-center ">
                            <div
                              className=" me-2"
                              style={{
                                height: "20px",
                                width: "20px",
                                backgroundColor: "#00FC7E",
                              }}
                            ></div>
                            <h1>APPLE ITUNES</h1>
                          </div>

                          <div className="flex align-items-center ">
                            <div
                              className=" me-2"
                              style={{
                                height: "20px",
                                width: "20px",
                                backgroundColor: "#900C3F",
                              }}
                            ></div>
                            <h1>SPOTIFY</h1>
                          </div>

                          <div className="flex align-items-center ">
                            <div
                              className=" me-2"
                              style={{
                                height: "20px",
                                width: "20px",
                                backgroundColor: "#3696A6",
                              }}
                            ></div>
                            <h1>JIO SAAVAN</h1>
                          </div>

                          <div className="flex align-items-center ">
                            <div
                              className=" me-2"
                              style={{
                                height: "20px",
                                width: "20px",
                                backgroundColor: "#FF3600",
                              }}
                            ></div>
                            <h1>AMAZON MUSIC</h1>
                          </div>

                          <div className="flex align-items-center ">
                            <div
                              className=" me-2"
                              style={{
                                height: "20px",
                                width: "20px",
                                backgroundColor: "#FFFFFF",
                              }}
                            ></div>
                            <h1>SOUNDCLOUD</h1>
                          </div>

                          <div className="flex align-items-center ">
                            <div
                              className=" me-2"
                              style={{
                                height: "20px",
                                width: "20px",
                                backgroundColor: "#FAF700",
                              }}
                            ></div>
                            <h1>YOUTUBE MUSIC</h1>
                          </div>

                          <div className="flex align-items-center ">
                            <div
                              className=" me-2"
                              style={{
                                height: "20px",
                                width: "20px",
                                backgroundColor: "#6E7C7A",
                              }}
                            ></div>
                            <h1>BEATPORT</h1>
                          </div>
                        </div>
                      </div>
                    }




                  </div>
                </div>
              </div>
            )}
          </div>


        </div>
      </div>
    </div>
  );
};

export default CustomerMangement;
