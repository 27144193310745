import React, { useState } from "react";
import axios, { axiosPrivate } from "../api/axios";
import "./Outlets.css";

import { useEffect } from "react";

import { useNavigate, useParams } from "react-router-dom";

function Gallery() {
  const navigate = useNavigate();

  const { id, type, name } = useParams();

  useEffect(() => {
    let isMounted = true;
    let outletId = localStorage.getItem("userId");

    const checkOutletAuth = async () => {
      const result = await axiosPrivate.get(`/events/${id}`);
      let event_owner_id = result.data.outlet_id;

      if (event_owner_id !== outletId) {
        navigate("/login");
      }
    };

    if (type === "events") {
      isMounted && checkOutletAuth();
    }

    return () => {
      isMounted = false;
    };
  }, []);

  const [imageFiles, setImageFiles] = useState([]);
  const [videoFiles, setVideoFiles] = useState([]);

  const [uploadProgress, setUploadProgress] = useState(0);

  const handleImageChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    setImageFiles(selectedFiles);
  };

  const handleVideoChange = (e) => {

    const selectedFiles = Array.from(e.target.files);
    setVideoFiles(selectedFiles);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Access input fields
    const posterInput = document.querySelector('input[name="poster_url"]');
    const introVideoInput = document.querySelector('input[name="intro_video"]');

    // Validation: Check if required fields have data
    if (!posterInput?.files.length && !introVideoInput?.files.length) {
      return alert("Please upload at least one poster image or introduction video to proceed.");
    }



    // Prepare form data for submission
    const imageFormData = new FormData();
    Array.from(posterInput.files).forEach((file) => imageFormData.append("icon", file));

    const videoFormData = new FormData();
    Array.from(introVideoInput.files).forEach((file) => videoFormData.append("video_url", file));

    // Map type to corresponding image and video types
    const typeMap = {
      event: { image: "event_images", video: "event_videos" },
      outlet: { image: "ambience_images", video: "experience_videos" },
      default: { image: "portrait_images", video: "show_videos" },
    };

    const { image: imageType, video: videoType } = typeMap[type] || typeMap.default;
    imageFormData.append("id", id);
    imageFormData.append("image_type", imageType);

    videoFormData.append("id", id);
    videoFormData.append("video_type", videoType);

    try {
      // Upload images and videos concurrently
      await Promise.all([
        axios.post("/images", imageFormData, {
          headers: { "Content-Type": "multipart/form-data" },
          onUploadProgress: (progressEvent) => {
            setUploadProgress(Math.round((progressEvent.loaded * 100) / progressEvent.total));
          },
        }),
        axios.post("/videos", videoFormData, {
          headers: { "Content-Type": "multipart/form-data" },
          onUploadProgress: (progressEvent) => {
            setUploadProgress(Math.round((progressEvent.loaded * 100) / progressEvent.total));
          },
        }),
      ]);

      // Success feedback and redirection
      alert("Upload Successful");

      const redirectRoutes = {
        event: `/singleEvent/${id}/${name}`,
        outlet: `/singleoutlet/${id}/${name}`,
        default: `/singleArtist/${id}/${name}`,
      };
      navigate(redirectRoutes[type] || redirectRoutes.default);

      // Reset the form and state
      setImageFiles([]);
      setVideoFiles([]);
      setUploadProgress(0);
    } catch (error) {
      console.error("Upload failed:", error);
      alert("Something went wrong. Please try again.");
    }
  };


  return (
    <div className="layout ">
      <div className="mx-auto mt-40 mb-5" style={{ height: "500px", width: "500px" }}>
        <h1 className="text-center " style={{ fontSize: "25px", fontWeight: "600", color: "white", letterSpacing: "1px", marginBottom: "80px", }} >
          MANAGE
          {type === "event" ? (
            <span className="mx-3">EVENT</span>
          ) : type === "outlet" ? (
            <span className="mx-3">OUTLET</span>
          ) : (
            <span className="mx-3">ARTIST</span>
          )}
          CONTENTS
        </h1>

        <div className="mt-3 mx-2 mb-3">
          <label style={{ color: "white" }} for="exampleFormControlInput1" className="form-label mb-2">
            {type === "event" ? (
              <span>ADD EVENT IMAGES</span>
            ) : type === "outlet" ? (
              <span>ADD TO AMBIENCE</span>
            ) : (
              <span>ADD TO PORTRAITS</span>
            )}
          </label>
          <input type="file" multiple name="poster_url" accept=".png, .jpg, .jpeg" onChange={handleImageChange} className="form-control" id="inputGroupFile04" aria-describedby="inputGroupFileAddon04" aria-label="Upload"
          />
        </div>

        <div className="mt-5 mx-2">
          <label style={{ color: "white" }} for="exampleFormControlInput1" className="form-label mb-2">
            {type === "event" ? (
              <span>ADD EVENT VIDEOS</span>
            ) : type === "outlet" ? (
              <span>ADD TO EXPERIENCE</span>
            ) : (
              <span>ADD TO SNIPPETS</span>
            )}
          </label>
          <input type="file" name="intro_video" multiple accept="video/*" onChange={handleVideoChange} className="form-control" id="inputGroupFile04" aria-describedby="inputGroupFileAddon04" aria-label="Upload"
          />
        </div>

        <div onClick={handleSubmit} className="mx-2 mt-4 flex align-items-center justify-content-center btnstyles">
          <h1 className="text-white" style={{ fontSize: "20px" }}>
            {" "}
            SUBMIT{" "}
          </h1>
        </div>
      </div>
    </div>
  );
}

export default Gallery;
