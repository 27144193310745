import React, { useEffect, useState } from "react";
import axios, { axiosPrivate } from "../api/axios";
import "./Outlets.css";

import { useNavigate } from "react-router-dom";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import StarIcon from "@mui/icons-material/Star";
import Rating from "@mui/material/Rating";

import Box from "@mui/material/Box";
import dayjs from "dayjs";

function SurveyForm() {
  const navigate = useNavigate();


  const maxDate = dayjs().subtract(16, "years");

  const [docCount, setDocCount] = useState({});
  const [admins, setAdmins] = useState([]);

  const userId = localStorage.getItem("userId");
  const userName = localStorage.getItem("userName");

  const [ratingform, setRatingForm] = useState({
    food: 0,
    ambience: 0,
    experience: 0,
  });



  const [rating, setRating] = useState([
    {
      category: "food",
      rating: 0,
    },
    {
      category: "ambience",
      rating: 0,
    },
    {
      category: "experience",
      rating: 0,
    }

  ]);



  const [hoverForm, setHoverForm] = useState({
    food: -1,
    ambience: -1,
    experience: -1,
  });


  const paddingLength = 10;

  useEffect(() => {
    let isMounted = true;

    const getAdmins = async () => {
      try {
        const response = await axios.get(`/user/admins`, {
          // signal: controller.signal
        });
        setAdmins(response.data);

      } catch (err) {
        console.error(err);
      }
    };

    const getDocCount = async () => {
      try {
        const response = await axios.get(`/documents`, {
          // signal: controller.signal
        });
        isMounted && setDocCount(response.data);
      } catch (err) {
        console.error(err);
      }
    };

    isMounted && getDocCount();
    isMounted && getAdmins();

    return () => {
      isMounted = false;
      // controller.abort();
    };
  }, []);

  const sendmessges = async () => {
    try {

      const response = await axiosPrivate.post(
        `/inbox`,
        {
          "message_receivers": JSON.stringify(
            [
              ...admins
                .filter(admin => admin.role !== "admin2")
                .map(admin => ({
                  'id': admin.userId,
                  'email_id': admin.email,
                  'name': admin.first_name + ' ' + admin.last_name
                })),
              {
                'id': form.outlet_id,
                'email_id': form.email,
                'name': form.outlet_name
              }
            ]
          ),
          "subject": "NEW CUSTOMER ADDED",
          "message": `New Customer has been successfully added  by ${form.outlet_name}.\n\n Recorded Details:\n
                          Name: ${form.first_name + ' ' + form.last_name} 
                          Email: ${form.email} 
                          Contact: ${form.phone}`,
          "category": "Survey"

        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );




    } catch (error) {
      console.error("Error sending message:", error.message);
      alert("An error occurred while sending message");
    }
  };
  const sendWelcomeMessage = async () => {

    const response = await axios.post('/user/sendWelcomeMessage', {
      email: form.email,
      name: form.first_name + ' ' + form.last_name,
      outlet_name: form.outlet_name
    });
  }

  const labels = {
    1: "Bad",
    2: "Poor",
    3: "Ok",
    4: "Good",
    5: "Excellent",
  };

  function getLabelText(value) {
    return `${value} Star${value !== 1 ? "s" : ""}, ${labels[value]}`;
  }






  const [form, setForm] = useState({
    first_name: "",
    last_name: "",
    userId: "",
    phone: "",
    email: "",
    password: "",
    dob: "",
    gender: "",
    role: "user",
    age_group: "",
    music_preference: "BOLLYWOOD (हिंदी)",
    event_preference: "LADIES NIGHT",
    music_platform: "APPLE ITUNES",
    outing_frequency: "ONCE EVERY WEEK",
    communication_preference: "EMAIL",
    outlet_id: "",
    outlet_name: ""

  });

  function generateRandomPassword(length) {
    // Define the character set for the password
    const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+-=[]{}|;:,.<>?";

    let password = '';

    // Generate random characters from the charset until the desired length is reached
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * charset.length);
      password += charset[randomIndex];
    }

    return password;
  }

  const [selectedDate, setSelectedDate] = useState(null);

  useEffect(() => {
    form.dob = selectedDate;
  }, [selectedDate]);

  useEffect(() => {
    form.outlet_id = userId;
    form.outlet_name = userName;
    const randomPassword = generateRandomPassword(12);
    form.password = randomPassword;
  }, [form.outlet_id, form.outlet_name, form.password, userId, userName])

  /* useEffect(() => {
     ;
   }, [form])*/

  useEffect(() => {

    form.userId = `NC${String(docCount.totalCustomers + 1).padStart(
      paddingLength,
      "0"
    )}`;
  }, [docCount, form.userId]);

  const handleRatingChange = (e) => {
    const { name, value } = e.target;

    // Update the form state
    setRatingForm({
      ...ratingform,
      [name]: value,
    });

    // Update the rating state based on the category
    setRating(prevRating => prevRating.map(item => {
      if (item.category === name) { // Remove the square brackets around e.target.name
        return {
          ...item,
          rating: parseInt(value) // Assuming the rating is a number
        };
      }
      return item;
    }));




  };


  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });





  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    if (date) {
      let today = new Date();
      let birthDate = new Date(date);
      let age = today.getFullYear() - birthDate.getFullYear();
      let monthDifference = today.getMonth() - birthDate.getMonth();
      if (
        monthDifference < 0 ||
        (monthDifference === 0 && today.getDate() < birthDate.getDate())
      ) {
        age--;
      }

      let calculatedAgeGroup = "";
      if (age >= 16 && age <= 20) {
        calculatedAgeGroup = "16-20";
      } else if (age >= 21 && age <= 25) {
        calculatedAgeGroup = "21-25";
      } else if (age >= 26 && age <= 30) {
        calculatedAgeGroup = "26-30";
      } else if (age >= 31 && age <= 35) {
        calculatedAgeGroup = "31-35";
      } else if (age >= 36 && age <= 40) {
        calculatedAgeGroup = "36-40";
      } else {
        calculatedAgeGroup = "40+";
      }
      setForm({ ...form, age_group: calculatedAgeGroup });
    }
  };

  const handleHoverChange = (e) => {
    setHoverForm({
      ...hoverForm,
      [e.target.name]: e.target.value,
    });
  };

  const submitServey = async () => {
    try {

      if (
        !form.first_name ||
        !form.last_name ||
        !form.phone ||
        !form.email ||
        !form.password ||
        !form.dob ||
        !form.gender
      ) {
        alert("Please Select All Fields")

      } else {
        const response = await axios.post(`/customers/add-servey`, form, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        if (response.status === 200) {
          /*  const response2 = await axios.patch(
              "/documents/totalCustomers"
            );*/
          alert("Customer updated successfully");
          handleSubmit();
          // Assuming you have the `navigate` function from your router
          sendmessges();
          sendWelcomeMessage();
          navigate("/");
        } else {
          alert("Failed to Add Customer");
        }
      }


    } catch (error) {
      console.error("Error adding Customer:", error.message);
      alert("An error occurred while adding Customer");
    }
  };

  const handleSubmit = async () => {
    try {
      const formData = new FormData();
      formData.append("from_id", form.userId);
      formData.append("to_id", userId);
      formData.append("ratings", JSON.stringify(rating));

      const response = await axiosPrivate.post(
        `/ratings/outlet`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );



    } catch (error) {
      console.error("Error updating insights:", error.message);
      alert("An error occurred while updating insights");
    }
  };

  return (
    <div className="parent">
      <div className="mx-auto" style={{ height: "auto", width: "90%", padding: "50px" }}>
        <h1
          className="text-center mb-3 mt-2"
          style={{
            fontSize: "25px",
            fontWeight: "500",
            color: "white",
            letterSpacing: "1px",
          }}
        >
          HELP US UNDERSTAND YOU BETTER
        </h1>

        <div className="flex justify-content-evenly flex-wrap pt-5 " style={{ backgroundColor: 'rgba(0,0,0,0.3)', height: '80%' }}>

          <div style={{ width: '45%' }}>
            <div className="mt-3 mx-2 ">
              <label
                style={{ color: "white" }}
                htmlFor="exampleFormControlInput1"
                className="form-label mb-2"
              >
                FIRST NAME
              </label>
              <input
                type="text"
                name="first_name"
                onChange={handleChange}
                className="form-control"
                id="exampleFormControlInput1"
                placeholder="Enter first name"
                required
              />
            </div>

            <div className="mt-3 mx-2 ">
              <label
                style={{ color: "white" }}
                htmlFor="exampleFormControlInput2"
                className="form-label mb-2"
              >
                LAST NAME
              </label>
              <input
                type="text"
                name="last_name"
                onChange={handleChange}
                className="form-control"
                id="exampleFormControlInput2"
                placeholder="Enter last name"
                required
              />
            </div>

            <div className=" mt-3 mx-2">
              <label
                style={{ color: "white" }}
                htmlFor="exampleFormControlInput4"
                className="form-label mb-2"
              >
                GENDER
              </label>
              <div className="row px-3">
                <div className="col   p-2" style={{ height: "50px" }}>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="gender"
                      id="gridRadios1"
                      value="Male"
                      onChange={handleChange}
                    />
                    <label
                      className="form-check-label text-light"
                      for="gridRadios1"
                    >
                      MALE
                    </label>
                  </div>
                </div>
                <div className="col  p-2 " style={{ height: "50px" }}>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="gender"
                      id="gridRadios2"
                      value="Female"
                      onChange={handleChange}
                    />
                    <label
                      className="form-check-label text-light"
                      for="gridRadios2"
                    >
                      FEMALE
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-3 mx-2 ">
              <label
                style={{ color: "white" }}
                htmlFor="exampleFormControlInput3"
                className="form-label mb-2"
              >
                CONTACT
              </label>
              <input
                type="text"
                name="phone"
                onChange={handleChange}
                className="form-control"
                id="exampleFormControlInput3"
                placeholder="Enter your mobile number"
                required
              />
            </div>

            <div className="mt-3 mx-2 ">
              <label
                style={{ color: "white" }}
                htmlFor="exampleFormControlInput4"
                className="form-label mb-2"
              >
                EMAIL ADDRESS
              </label>
              <input
                type="email"
                name="email"
                onChange={handleChange}
                className="form-control"
                id="exampleFormControlInput4"
                placeholder="Enter email"
                required
              />
            </div>

            <div className=" mt-3 mx-2 ">
              <label
                style={{ color: "white" }}
                htmlFor="exampleFormControlInput6"
                className="form-label mb-2"
              >
                DOB
              </label>
              <div className="bg-light rounded">
                <LocalizationProvider
                  className="bg-light"
                  dateAdapter={AdapterDayjs}
                // style={{ width: "100%" }}
                >
                  <DatePicker
                    className="w-full container-fluid p-0"
                    label={"Select Date"}
                    id="exampleFormControlInput6"
                    value={selectedDate}
                    onChange={handleDateChange}
                    format="DD/MM/YYYY"
                    views={["year", "month", "day"]}
                    required
                    maxDate={maxDate} // Set the minimum date
                  />
                </LocalizationProvider>
              </div>
              <p className="text-light mt-2" style={{ fontSize: "12px" }}>
                ** YOU HAVE TO BE MINIMUM 16 YEARS OR OLDER
              </p>
            </div>


          </div>

          <div style={{ width: '45%' }}>
            <div className="mt-3 mx-2 ">
              <label
                style={{ color: "white", textTransform: 'uppercase' }}
                for="exampleFormControlInput1"
                className="form-label mb-2"
              >
                Help Us Discover Your Musical Tastes:
              </label>
              <select
                name="music_preference"
                onChange={handleChange}
                className="form-select"
                aria-label="Default select example"
              >
                {/* <option selected>Open this select menu</option> */}
                <option value="BOLLYWOOD (हिंदी)">BOLLYWOOD (हिंदी)</option>
                <option value="EDM">EDM</option>
                <option value="TECHNO/UNDERGROUND">TECHNO/UNDERGROUND</option>
                <option value="DUBSTEP/BASS">DUBSTEP/BASS</option>
                <option value="BIG ROOM/ELECTRO">BIG ROOM/ELECTRO</option>
                <option value="DEEP/HOUSE">DEEP/HOUSE</option>
                <option value="CHILL/HOUSE">CHILL/HOUSE</option>
                <option value="HIP-HOP/TRAP">HIP-HOP/TRAP</option>
                <option value="LIVE BAND/METAL">LIVE BAND/METAL</option>
                <option value="KANNADA (ಕನ್ನಡ)">KANNADA (ಕನ್ನಡ)</option>
              </select>
            </div>

            <div className="mt-3 mx-2">
              <label
                style={{ color: "white", textTransform: 'uppercase' }}

                for="exampleFormControlInput1"
                className="form-label mb-2"
              >
                Let Us Know Your Preferred Social Experiences:
              </label>
              <select
                name="event_preference"
                onChange={handleChange}
                className="form-select"
                aria-label="Default select example"
              >
                {/* <option selected>Open this select menu</option> */}
                <option value="LADIES NIGHT">LADIES NIGHT</option>
                <option value="BRUNCH">BRUNCH</option>
                <option value="FRIDAY NIGHT LIVE">FRIDAY NIGHT LIVE</option>
                <option value="SATURDAY NIGHT LIVE">SATURDAY NIGHT LIVE</option>
                <option value="SUNDAY NIGHT LIVE">SUNDAY NIGHT LIVE</option>
                <option value="COSTUME & THEMES">COSTUME & THEMES</option>
                <option value="CONCEPTS">CONCEPTS</option>
                <option value="HAPPY HOURS">HAPPY HOURS</option>
              </select>
            </div>

            <div className="mt-3 mx-2">
              <label
                style={{ color: "white", textTransform: 'uppercase' }}

                for="exampleFormControlInput1"
                className="form-label mb-2"
              >
                Which Radio You Tune In For Listening Music:
              </label>
              <select
                name="music_platform"
                onChange={handleChange}
                className="form-select"
                aria-label="Default select example"
              >
                {/* <option selected>Open this select menu</option> */}
                <option value="APPLE ITUNES">APPLE ITUNES</option>
                <option value="SPOTIFY">SPOTIFY</option>
                <option value="JIO SAAVAN">JIO SAAVAN</option>
                <option value="AMAZON MUSIC">AMAZON MUSIC</option>
                <option value="SOUNDCLOUD">SOUNDCLOUD</option>
                <option value="YOUTUBE MUSIC">YOUTUBE MUSIC</option>
                <option value="BEATPORT">BEATPORT</option>
              </select>
            </div>

            <div className="mt-3 mx-2">
              <label
                style={{ color: "white", textTransform: 'uppercase' }}

                for="exampleFormControlInput1"
                className="form-label mb-2"
              >
                Help Us Understand Your Event Participation:
              </label>
              <select
                name="outing_frequency"
                onChange={handleChange}
                className="form-select"
                aria-label="Default select example"
              >
                {/* <option selected>Open this select menu</option> */}
                <option value="ONCE EVERY WEEK">ONCE EVERY WEEK</option>
                <option value="ONCE EVERY TWO WEEK">ONCE EVERY TWO WEEK</option>
                <option value="ONCE EVERY MONTH">ONCE EVERY MONTH</option>
              </select>
            </div>

            <div className="mt-3 mx-2">
              <label
                style={{ color: "white", textTransform: 'uppercase' }}

                for="exampleFormControlInput1"
                className="form-label mb-2"
              >
                How Can We Tailor Our Communication to Your Preference:
              </label>
              <select
                name="communication_preference"
                onChange={handleChange}
                className="form-select"
                aria-label="Default select example"
              >
                {/* <option selected>Open this select menu</option> */}
                <option value="EMAIL">EMAIL</option>
                <option value="WHATSAPP">WHATSAPP</option>
                <option value="MESSAGES">MESSAGES</option>
              </select>
            </div>

            <div className="mt-3 mx-2 ">
              <label
                style={{ color: "white", textTransform: 'uppercase' }}

                for="exampleFormControlInput1"
                className="form-label mb-2"
              >
                Review Your Experience With Us :
              </label>
              <Box
                sx={{
                  width: 300,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <h1 style={{ width: '100px' }} className="me-2 mt-1">AMBIENCE : </h1>
                <Rating
                  name="ambience"
                  value={ratingform.ambience}
                  precision={1}
                  getLabelText={getLabelText}
                  onChange={(e) => handleRatingChange(e)}
                  onChangeActive={(e) => handleHoverChange(e)}
                  emptyIcon={
                    <StarIcon style={{ opacity: 0.55, color: 'white' }} fontSize="inherit" />
                  }
                />
                {ratingform.ambience !== null && (
                  <Box sx={{ ml: 2 }}>
                    {
                      labels[
                      hoverForm.ambience !== -1
                        ? hoverForm.ambience
                        : ratingform.ambience
                      ]
                    }
                  </Box>
                )}
              </Box>

              <Box
                className="flex align-items-center "
                sx={{
                  width: 300,

                }}
              >
                <h1 style={{ width: '100px' }} className="me-2 mt-1">FOOD : </h1>
                <Rating
                  name="food"
                  value={ratingform.food}
                  precision={1}
                  getLabelText={getLabelText}
                  onChange={(e) => handleRatingChange(e)}
                  onChangeActive={(e) => handleHoverChange(e)}
                  emptyIcon={
                    <StarIcon style={{ opacity: 0.55, color: 'white' }} fontSize="inherit" />
                  }
                />
                {ratingform.food !== null && (
                  <Box sx={{ ml: 2 }}>
                    {labels[hoverForm.food !== -1 ? hoverForm.food : ratingform.food]}
                  </Box>
                )}
              </Box>

              <Box
                sx={{
                  width: 300,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <h1 style={{ width: '100px' }} className="me-2 mt-1">EXPERIENCE : </h1>
                <Rating
                  name="experience"
                  value={ratingform.experience}
                  precision={1}
                  getLabelText={getLabelText}
                  onChange={(e) => handleRatingChange(e)}
                  onChangeActive={(e) => handleHoverChange(e)}
                  emptyIcon={
                    <StarIcon style={{ opacity: 0.55, color: 'white' }} fontSize="inherit" />
                  }
                />
                {ratingform.experience !== null && (
                  <Box sx={{ ml: 2 }}>
                    {
                      labels[
                      hoverForm.experience !== -1
                        ? hoverForm.experience
                        : ratingform.experience
                      ]
                    }
                  </Box>
                )}
              </Box>
            </div>
          </div>
        </div>
        <div className="text-center">
          <button className="btn btn-primary" onClick={submitServey} >SUBMIT</button>
        </div>

      </div>

    </div>
  );
}

export default SurveyForm;
