import React from "react";
import "./Outlets.css";
import Footer from "../components/Footer";

import { useState, useEffect } from "react";
import { axiosPrivate } from "../api/axios";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { useNavigate } from "react-router-dom";

import { Banner } from "../components/Banner";
import "../components/styles.css";

import { useInView } from "react-intersection-observer";

import SearchIcon from "@mui/icons-material/Search";

import { BASE_URL } from "../constants";

const Outlets = () => {
  const navigate = useNavigate();

  const [outletData, setOutletData] = useState([]);

  const [nightclubs, setNightclubs] = useState([]);
  const [lounges, setLounges] = useState([]);
  const [concertHalls, setConcertHalls] = useState([]);
  const [pubsandBreweries, setPubsandBreweries] = useState([]);
  const [openAir, setOpenAir] = useState([]);
  const [rooftopcafeAndBars, setRooftopcafeAndBars] = useState([]);
  const [restoCafes, setRestocafes] = useState([]);
  const [poolside, setPoolside] = useState([]);

  const [nightclubsFilter, setNightclubsFilter] = useState([]);
  const [loungesFilter, setLoungesFilter] = useState([]);
  const [concertHallsFilter, setConcertHallsFilter] = useState([]);
  const [pubsandBreweriesFilter, setPubsandBreweriesFilter] = useState([]);
  const [openAirFilter, setOpenAirFilter] = useState([]);
  const [rooftopcafeAndBarsFilter, setRooftopcafeAndBarsFilter] = useState([]);
  const [restoCafesFilter, setRestocafesFilter] = useState([]);
  const [poolsideFilter, setPoolsideFilter] = useState([]);

  const [outleticons, setOutletIcons] = useState([]);

  const [search, setSearch] = useState("");

  const [outletVideoIndex, setOutletVideoIndex] = useState(0);

  const [promotedOutlets, setPromotedOutlets] = useState([]);

  const [ref, inView] = useInView({
    triggerOnce: true, // Animation triggers only once when the element comes into view
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setOutletVideoIndex((prevIndex) => (prevIndex + 1) % 5);
    }, 10000); // Change the interval (in milliseconds) as needed

    return () => clearInterval(interval);
  }, []);

  const settings = {
    dots: false,
    infinite: false,
    speed: 50000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 50000,
    arrows: true,
  };

  useEffect(() => {
    let isMounted = true;

    const getOutletData = async () => {
      const response = await axiosPrivate.get("/outlets", {});
      setOutletData(response.data);
    };

    isMounted && getOutletData();

    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    if (outletData.length > 0) {
      const images = outletData?.map((outlet) => ({
        id: outlet.outlet_id,
        name: outlet.outlet_name,
        image: outlet.outlet_icon,
      }));

      setOutletIcons(images);
    }
  }, [outletData]);

  const getOutletsbyCategory = async (outletCategory) => {
    try {
      const response = await axiosPrivate.get(
        `/outlets/type/${outletCategory}`
      );
      // isMounted && setAllEvents(response.data);
      if (outletCategory === "PUB & BREWERY") {
        setPubsandBreweries(response.data);
        setPubsandBreweriesFilter(response.data);
      } else if (outletCategory === "POOLSIDE") {
        setPoolside(response.data);
        setPoolsideFilter(response.data);
      } else if (outletCategory === "CONCERT HALL") {
        setConcertHalls(response.data);
        setConcertHallsFilter(response.data);
      } else if (outletCategory === "LOUNGE") {
        setLounges(response.data);
        setLoungesFilter(response.data);
      } else if (outletCategory === "OPEN AIR") {
        setOpenAir(response.data);
        setOpenAirFilter(response.data);
      } else if (outletCategory === "ROOFTOP CAFE AND BAR") {
        setRooftopcafeAndBars(response.data);
        setRooftopcafeAndBarsFilter(response.data);
      } else if (outletCategory === "RESTO CAFE AND BAR") {
        setRestocafes(response.data);
        setRestocafesFilter(response.data);
      } else {
        setNightclubs(response.data);
        setNightclubsFilter(response.data);
      }
    } catch (err) {
      console.error(err);
      // navigate('/login', { state: { from: location }, replace: true });
    }
  };

  useEffect(() => {
    let isMounted = true;

    const getPromotedOutlets = async () => {
      const response = await axiosPrivate.get(`/outlets/promoted`);

      setPromotedOutlets(response.data);
    };

    isMounted && getOutletsbyCategory("NIGHTCLUB");
    isMounted && getOutletsbyCategory("POOLSIDE");
    isMounted && getOutletsbyCategory("CONCERT HALL");
    isMounted && getOutletsbyCategory("LOUNGE");
    isMounted && getOutletsbyCategory("OPEN AIR");
    isMounted && getOutletsbyCategory("RESTO CAFE AND BAR");
    isMounted && getOutletsbyCategory("PUB & BREWERY");
    isMounted && getOutletsbyCategory("ROOFTOP CAFE AND BAR");

    isMounted && getPromotedOutlets();

    return () => {
      isMounted = false;
    };
  }, []);

  const handleSearch = (search) => {
    const filterOutlet = (outlets) => {
      return outlets.filter(
        (outlet) =>
          outlet?.name &&
          outlet.name.toLowerCase().includes(search.toLowerCase())
      );
    };

    let nightclub_filter = filterOutlet(nightclubs);
    setNightclubsFilter(nightclub_filter);

    let poolside_filter = filterOutlet(poolside);
    setPoolsideFilter(poolside_filter);

    let concert_filter = filterOutlet(concertHalls);
    setConcertHallsFilter(concert_filter);

    let lounge_filter = filterOutlet(lounges);
    setLoungesFilter(lounge_filter);

    let openair_filter = filterOutlet(openAir);
    setOpenAirFilter(openair_filter);

    let restocafe_filter = filterOutlet(restoCafes);
    setRestocafesFilter(restocafe_filter);

    let pub_filter = filterOutlet(pubsandBreweries);
    setPubsandBreweriesFilter(pub_filter);

    let rooftop_filter = filterOutlet(rooftopcafeAndBars);
    setRooftopcafeAndBarsFilter(rooftop_filter);
  };

  return (
    <div className="layout mb-5">
      <div className="firstwindow ">
        <div className="firstwindowleft">
          <h1 ref={ref} className={`mainheading animated-element ${
              inView ? "animate" : ""
            }`} >
            SPOTS TO EXPLORE
          </h1>
        </div>
        <div className="  flex justify-content-end firstwindowright">
          {promotedOutlets.map((outlet, index) => (
            <video
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
              key={index}
              autoPlay
              loop
              muted
              className={index === outletVideoIndex ? "visible" : "hidden"} >
              <source
                src={`${BASE_URL}${outlet.intro_video}`}
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>
          ))}
        </div>
      </div>

      <div>
        <div className=" mb-5 flex flex-column col-md-12">
          {nightclubsFilter.length > 0 && (
            <div>
              <Banner
                images={nightclubsFilter}
                type="singelEvent"
                margin="8px"
                 />
              <h1 className="nightclubstext text-light mt-5  font-semibold"  style={{ fontSize: "22px" }}>
                NIGHTCLUBS
              </h1>
              <hr className="text-warning" style={{ marginTop: "10px" }} />
            </div>
          )}
        </div>

        <div className="mt-5 mb-5 flex flex-column ">
          {loungesFilter.length > 0 && (
            <div>
              <Banner
                images={loungesFilter}
                type="singelEvent"
                margin="8px" />
              <h1  className="nightclubstext text-light mt-5  font-semibold"  style={{ fontSize: "22px" }}>
                LOUNGES
              </h1>
              <hr className="text-warning" style={{ marginTop: "10px" }} />
            </div>
          )}
        </div>

        <div className="mt-5 mb-5 flex flex-column justify-content-start">
          {restoCafesFilter.length > 0 && (
            <div>
              <Banner
                images={restoCafesFilter}
                margin="8px"/>
              <h1 className="nightclubstext text-light mt-5  font-semibold"
                style={{ fontSize: "22px" }} >
                RESTO CAFE AND BARS
              </h1>
              <hr className="text-warning" style={{ marginTop: "10px" }} />
            </div>
          )}
        </div>

        <div className="mt-5 mb-5 flex flex-column ">
          {pubsandBreweriesFilter.length > 0 && (
            <div>
              <Banner
                images={pubsandBreweriesFilter}
                type="singelEvent"
                margin="8px" />
              <h1
                className="nightclubstext text-light mt-5  font-semibold"
                style={{ fontSize: "22px" }}>
                PUBS & BREWERIES
              </h1>
              <hr className="text-warning" style={{ marginTop: "10px" }} />
            </div>
          )}
        </div>

        <div className="mt-5 mb-5 flex flex-column justify-content-start">
          {openAirFilter.length > 0 && (
            <div>
              <Banner
                images={openAirFilter}
                type="singelEvent"
                margin="8px"/>
              <h1
                className="nightclubstext text-light mt-5  font-semibold"
                style={{ fontSize: "22px" }} >
                OPEN AIR
              </h1>
              <hr className="text-warning" style={{ marginTop: "10px" }} />
            </div>
          )}
        </div>

        <div className="mt-5 mb-5 flex flex-column ">
          {rooftopcafeAndBarsFilter.length > 0 && (
            <div>
              <Banner
                images={rooftopcafeAndBarsFilter}
                type="singelEvent"
                margin="8px"
              />
              <h1
                className="nightclubstext text-light mt-5  font-semibold"
                style={{ fontSize: "22px" }}
              >
                ROOFTOP CAFE AND BARS
              </h1>
              <hr className="text-warning" style={{ marginTop: "10px" }} />
            </div>
          )}
        </div>

        <div className="mt-5 mb-5 flex flex-column justify-content-start">
          {concertHallsFilter.length > 0 && (
            <div>
              <Banner
                images={concertHallsFilter}
                type="singelEvent"
                margin="8px" />
              <h1 className="nightclubstext text-light mt-5  font-semibold"
                style={{ fontSize: "22px" }}>
                CONCERT HALLS
              </h1>
              <hr className="text-warning" style={{ marginTop: "10px" }} />
            </div>
          )}
        </div>

        <div className="mt-5 mb-5 flex flex-column ">
          {poolsideFilter.length > 0 && (
            <div>
              <Banner
                images={poolsideFilter}
                type="singelEvent"
                margin="8px" />
              <h1
                className="nightclubstext text-light mt-5  font-semibold"
                style={{ fontSize: "22px" }} >
                POOLSIDE
              </h1>
              <hr className="text-warning" style={{ marginTop: "10px" }} />
            </div>
          )}
        </div>
      </div>

      <div style={{ marginTop: "50px" }} className="footer-overlay">
          <Footer />
        </div>
    </div>
  );
};

export default Outlets;
