import React, { useEffect, useState } from "react";


import default_collection from "../assets/errors/default_collection.jpg";



import "./Outlets.css";


import "../assets/SingleOutlet.css";

import EditNoteIcon from "@mui/icons-material/EditNote";
import CircularProgress from "@mui/material/CircularProgress";
import { axiosPrivate } from "../api/axios";

import event_not_listed from "../assets/errors/no_event_listed.jpg";

import { useNavigate } from "react-router-dom";

import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

import { BASE_URL } from '../constants';



const UserProfilePage = () => {
  const [userData, setUserData] = useState({});

  const [userCollection, setUserCollection] = useState([]);

  const [upComingEventsByMusic, setUpComingEventsByMusic] = useState([]);
  const [liveEventsByMusic, setLiveEventsByMusic] = useState([]);
  const [previousEventsByMusic, setPreviousEventsByMusic] = useState([]);

  const navigate = useNavigate();



  useEffect(() => {
    let isMounted = true;
    let customerId = localStorage.getItem("userId");
    const getUserData = async () => {
      try {
        const response = await axiosPrivate.get(`/customers/${customerId}`, {
          // // signal: controller.signal
          // 'Access-Control-Allow-Origin' : true
        });
        setUserData(response.data);

      } catch (err) {
        console.error(err);
        // navigate('/login', { state: { from: location }, replace: true });
      }
    };

    const getUserCollections = async () => {
      try {
        const response = await axiosPrivate.get(
          `/reservations/byuserid/${customerId}`
        );
        setUserCollection(response.data);
      } catch (err) {
        console.error(err);
        // navigate('/login', { state: { from: location }, replace: true });
      }
    };

    isMounted && getUserData();
    isMounted && getUserCollections();

    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    let isMounted = true;

    const getUpcomingEventsByCategory = async () => {
      try {
        const formattedMusicCategory = userData.music_preference
          ?.replace(/[^a-zA-Z0-9/-]/g, '~').
          replace(/\//g, '_');
        console.log("getUpcomingEventsByCategory - formattedMusicCategory: ", formattedMusicCategory)
        const response = await axiosPrivate.get(
          `/events/upcoming_eventsbymusic/${formattedMusicCategory}`,
          {
            // // signal: controller.signal
            // 'Access-Control-Allow-Origin' : true
          }
        );
        console.log(response.data)
        setUpComingEventsByMusic(response.data);

      } catch (err) {
        console.error(err);
        // navigate('/login', { state: { from: location }, replace: true });
      }
    };

    const getPreviousEventsByCategory = async () => {
      try {

        const formattedMusicCategory = userData.music_preference
          ?.replace(/[^a-zA-Z0-9/-]/g, '~').replace(/\//g, '_');
        console.log("music pref", userData.music_preference)
        console.log("prev muisc cat->", formattedMusicCategory)
        const response = await axiosPrivate.get(
          `/events/previous_eventsbymusic/${formattedMusicCategory}`,
          {
            // // signal: controller.signal
            // 'Access-Control-Allow-Origin' : true
          }
        );
        setPreviousEventsByMusic(response.data);

      } catch (err) {
        console.error(err);
        // navigate('/login', { state: { from: location }, replace: true });
      }
    };

    const getLiveEventsByCategory = async () => {
      try {
        const formattedMusicCategory = userData.music_preference
          ?.replace(/[^a-zA-Z0-9/-]/g, '~').
          replace(/\//g, '_');
        console.log("formated value of live event", formattedMusicCategory)
        const response = await axiosPrivate.get(
          `/events/live_eventsbymusic/${formattedMusicCategory}`,
          {
            // // signal: controller.signal
            // 'Access-Control-Allow-Origin' : true
          }
        );
        setLiveEventsByMusic(response.data);

      } catch (err) {
        console.error(err);
        // navigate('/login', { state: { from: location }, replace: true });
      }
    };
    
    console.log("liveevent-mouted", isMounted)
    isMounted && getUpcomingEventsByCategory();
    isMounted && getPreviousEventsByCategory();
    isMounted && getLiveEventsByCategory();

    return () => {
      isMounted = false;
    };
  }, [userData.music_preference]);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false,
  };

  return (
    <div className="layout ">
      <div className="mainsection text-light ">
        <div className="flex justify-content-between  flex-wrap ">
          {upComingEventsByMusic?.length > 0 ? (
            <div
              onClick={() => navigate("/allevents/upcoming_events", {
                state: {
                  musicCategory:
                    userData?.music_preference
                }
              })}
              className="flex-column  cursor-pointer align-items-center imagesinglecard mt-5"
              style={{ backgroundColor: "rgb(186 186 186 / 6%)" }} >
                
              <Slider {...settings} className="">
                {upComingEventsByMusic?.map((event, index) => (
                  <div key={index}>
                    <img
                      src={`${BASE_URL}${event.poster_url}`}
                      alt={event.event_name}
                      className="all-evt-cards"
                    />
                  </div>
                ))}
              </Slider>

              <div
                style={{ height: "10%", width: "100%" }}
                className="flex align-items-center  justify-center ">
                <h1
                  className="text-center font-semibold "
                  style={{ left: "50%", color: "white" }}>
                  COMING SOON YOU MIGHT EXPLORE
                </h1>
              </div>
            </div>
          ) : (
            <div
            className="flex flex-column  cursor-pointer imagesinglecard mt-5">
              <img
                src={event_not_listed}
                alt="default_event"
                className="eventimage"
              />

              <div
                style={{ height: "10%", width: "100%" }}
                className="flex align-items-center  justify-center mt-2 mb-2 ">
                <h1
                  className="text-center font-semibold "
                  style={{ left: "50%", color: "white" }} >
                  COMING SOON YOU MIGHT EXPLORE
                </h1>
              </div>
            </div>
          )}

          {liveEventsByMusic?.length > 0 ? (
            <div className="flex-column  cursor-pointer align-items-center imagesinglecard mt-5"
              onClick={() => navigate("/allevents/live_events")} >
              <Slider {...settings}>
                {liveEventsByMusic?.map((event, index) => (
                  <div
                    key={index}
                  >
                    <img
                      src={`${BASE_URL}${event.poster_url}`}
                      // src="./assets/default_images/default_image.jpg"
                      alt={event.event_name}
                      className="all-evt-cards"
                    />
                  </div>
                ))}
              </Slider>
              <div style={{ height: "10%", width: "100%" }}
                className="flex align-items-center  justify-center " >
                <h1 className="text-center font-semibold "
                  style={{ left: "50%", color: "white" }} >
                  LIVE NOW
                </h1>
                <CircularProgress
                  className="ms-2"
                  size={18}
                  sx={{ color: "green" }}
                  thickness={4}
                />
              </div>
            </div>
          ) : (
            <div className="flex flex-column cursor-pointer imagesinglecard mt-5" >
              
              <img src={event_not_listed}
                // src="../assets/default_image.jpg"
                alt="default_image"
                className="eventimage" />

              <div style={{ height: "10%", width: "100%" }}
                className="flex align-items-center  justify-center mt-2 mb-2 " >
                <h1
                  className="text-center font-semibold "
                  style={{ left: "50%", color: "white" }} >
                  LIVE NOW
                </h1>
                <CircularProgress
                  className="ms-2"
                  size={18}
                  sx={{ color: "green" }}
                  thickness={4} />
              </div>
            </div>
          )}

          {previousEventsByMusic?.length > 0 ? (
            <div onClick={() => navigate("/allevents/previous_events", {
                state: {
                  musicCategory:
                    userData?.music_preference
                }
              })}
              className="flex-column mt-5 cursor-pointer align-items-center imagesinglecard user-profile-cards"  >
              <Slider {...settings} className="">
                {previousEventsByMusic?.map((event, index) => (
                  <div
                    key={index}
                  >
                    <img
                      src={`${BASE_URL}${event.poster_url}`}
                      alt={event?.event_name}
                      className="all-evt-cards"
                    />
                  </div>
                ))}
              </Slider>
              <div style={{ height: "10%", width: "100%" }}
                className="flex align-items-center   justify-center "
              >
                <h1
                  className="text-center font-semibold "
                  style={{ left: "50%", color: "white" }}
                >
                  MIGHT HAVE MISSED OUT ON
                </h1>
              </div>
            </div>
          ) : (
            <div className="flex flex-column mt-5 cursor-pointer imagesinglecard user-profile-cards" >
              <img src={event_not_listed}
                alt="default_event"
                className="eventimage" />

              <div style={{ height: "0%", width: "100%" }}
                className="flex align-items-center justify-center " >
                <h1 className="text-center font-semibold " style={{ left: "50%", color: "white" }} >
                  MIGHT HAVE MISSED OUT ON
                </h1>
              </div>
            </div>
          )}
        </div>

        <div className="mobile-gaps"></div>

       
        {/* =================new */}
        <div className="mb-5 mt-5 pt-5">
          <div className="row justify-content-center g-4 mt-5">
            <div className="col-sm-12 col-md-6 col-lg-4 mt-5">
              <h1 className="text-center h5 mt-4 mb-2 text-light font-semibold">My COLLECTIONS </h1>
              <div className="card  user-cards" style={{
                  backgroundColor: "transparent",
                  // height: "100%",  width:"100%" 
                }} >

                {userCollection.length > 0 ?
                  <Slider {...settings} style={{ display: "inline-grid" }}>
                    {userCollection?.map((event, index) => (
                      <div
                        key={index}
                      // style={{ height: "500px", width: "500px" }}
                      >
                        <img
                          onClick={() => navigate(`/singleEvent/${event.event_id}/${event.event_name}`)}
                          // style={{
                          //   height: "500px",
                          //   width: "500px",
                          //   objectFit: "cover",
                          //   cursor: 'pointer'
                          // }}
                          className="user-profile-img"

                          src={`${BASE_URL}${event.poster_url}`}
                          alt={event.event_name}
                        />
                      </div>
                    ))}
                  </Slider>

                  :

                  <div>
                    <img className="user-profile-img"
                      src={default_collection}
                      alt="default_collection" />
                  </div>
                }
              </div>

            </div>


            <div className="col-sm-12 col-md-6 col-lg-4 mt-5">
              <h1 className="text-center h5 mt-4 mb-2 text-light font-semibold">
                My PREFERENCES
              </h1>
              <div className="card user-cards user-profile-cards user-profile-details-cards "
                 style={{  
                  overflow: "hidden",
                  position: "relative",
                  overflowX: "auto",
                }} >
                <div onClick={() => navigate("/user-servey", {
                    state:
                      userData?.music_preference
                  })}
                  className="flex border rounded justify-content-around align-items-center cursor-pointer"
                  style={{
                    height: "auto",
                    width: "85px",
                    position: "absolute",
                    top: "15px",
                    right: "15px", }} >
                  <button style={{ color: "white" }}>EDIT</button>
                  <EditNoteIcon style={{ color: "white" }} />
                </div>

                <div className="card-body flex flex-column justify-content-center ">

                  <dl className="row">

                    <dt className="col-md-4 mb-3 ">
                      <h5 className=" text-light font-bold "> MUSIC :</h5>
                    </dt>
                    <dd className="col-md-8 mb-3">
                      <p className="text-light">
                        {userData.music_preference}
                      </p>
                    </dd>

                     <hr className="mb-3"/>

                    <dt className="col-md-4 mb-3 ">
                      <h5 className=" text-light font-bold "> EVENT :</h5>
                    </dt>
                    <dd className="col-md-8 mb-3">
                      <p className="text-light">
                        {userData.event_preference}
                      </p>
                    </dd>

                     <hr className="mb-3"/>

                    <dt className="col-md-4 mb-3  ">
                      <h5 className=" text-light font-bold "> RADIO :</h5>
                    </dt>
                    <dd className="col-md-8 mb-3">
                      <p className="text-light">
                        {userData.music_platform}
                      </p>
                    </dd>

                     <hr className="mb-3"/>

                    <dt className="col-md-4 mb-3 ">
                      <h5 className=" text-light font
                      bold "> OUTING :</h5>
                    </dt>
                    <dd className="col-md-8 mb-3">
                      <p className="text-light">
                        {userData.outing_frequency}
                      </p>
                    </dd>

                    <hr className="mb-3"/>

                    <dt className="col-md-4 mb-3 ">
                      <h5 className=" text-light font-bold "> COMMS :</h5>
                    </dt>
                    <dd className="col-md-8 mb-3">
                      <p className="text-light">
                        {userData.communication_preference}
                      </p>
                    </dd>


                  </dl>
                 
                </div>
              </div>
            </div>


            <div className="col-sm-12 col-md-6 col-lg-4 mt-5">
              <h1 className="text-center h5 mt-4 mb-2 text-light font-semibold ">
                My BIOGRAPHY
              </h1>
              <div className="card user-cards user-profile-cards user-profile-details-cards"
                 style={{ 
                  // height: "500px",
                  // width:"400px",
                  overflow: "hidden",
                  position: "relative",
                  overflowX: "auto",
                }} >

                <div className="card-body  flex flex-column justify-content-center ">
                  <dl className="row">
                    <dt className="col-md-4 mb-3">
                      <h5 className=" text-light font-bold "> NAME :</h5>
                    </dt>
                    <dd className="col-md-8 mb-3">
                      <p className="text-light">
                        {userData.customer_first_name}{" "}
                        {userData.customer_last_name}
                      </p>
                    </dd>

                    <hr className="mb-3"/>

                    <dt className="col-md-4 mb-3">
                      <h5 className=" text-light font-bold "> CONTACT :</h5>
                    </dt>
                    <dd className="col-md-8 mb-3">
                      <p className="text-light">{userData.phone}</p>
                    </dd>

                    <hr className="mb-3"/>

                    <dt className="col-md-4 mb-3">
                      <h5 className=" text-light font-bold "> EMAIL :</h5>
                    </dt>
                    <dd className="col-md-8">
                      <p className="text-light">{userData.email}</p>
                    </dd>

                    <hr className="mb-3"/>

                    <dt className="col-md-4 mb-3">
                      <h5 className=" text-light font-bold "> GENDER :</h5>
                    </dt>
                    <dd className="col-md-8 mb-3">
                      <p className="text-light">{userData.gender}</p>
                    </dd>

                    <hr className="mb-3"/>

                    <dt className="col-md-4 mb-3">
                      <h5 className=" text-light font-bold "> AGE GROUP :</h5>
                    </dt>
                    <dd className="col-md-8 mb-3">
                      <p className="text-light">{userData.age_group}</p>
                    </dd>
                  </dl>
                  
                </div>
              </div>
            </div>

          </div>
        </div>


      </div>

      {/* <div className="footer-overlay">
        <Footer />
      </div> */}

    </div>
  );
};

export default UserProfilePage;
