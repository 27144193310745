

import { useNavigate } from 'react-router-dom';
import React, { useState, useRef, useEffect } from 'react';
import Slider from 'react-slick';
import { BASE_URL } from '../constants';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Banner = ({ images, speed = 3000, direction = 'left', height = "280px", width ='280px', margin = '0px', type }) => {
  const sectionClassName = direction === 'left' ? 'section1' : 'section2';
  const navigate = useNavigate();
  const sectionRef = useRef(null);

  const settings = {
    dots: false,
    infinite: true,
    speed: speed, // Use the passed speed
    slidesToShow: 5,
    slidesToScroll: 2,
    autoplay: true,
    autoplaySpeed: 500,
    arrows: true,
    centerMode: true,  // Focus on the center slide   
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const handleNavigation = (name, id) => {
    if (type === 'images') {
      navigate(`/images/outlets/${id}/${name.replace(/ /g, '_')}`);
    } else if (type === 'videos') {
      navigate(`/videos/experience_videos/${id}/${name.replace(/ /g, '_')}`);
    } else if (type === 'event') {
      navigate(`/singleEvent/${id}/${name.replace(/ /g, '_')}`);
    } else {
      navigate(`/singleoutlet/${id}/${name.replace(/ /g, '_')}`);
    }
  };

  return (
    <div className="inner">
      <div className="wrapper">
        <section ref={sectionRef} className={sectionClassName}>
          <Slider {...settings}>
            {images.map(({ id, image, name }) => (
              <div key={id}>
                <div className="image" style={{ margin }}>
                  <img
                    onClick={() => handleNavigation(name, id)}
                    className="sliderimg"
                    style={{ height, width: width, objectFit: 'cover' }}
                    src={`${BASE_URL}${image}`}
                    alt={id}
                  />
                </div>
                <div>
                  <h3 className="text-light text-center p-2 font-semibold">{name}</h3>
                </div>
              </div>
            ))}
          </Slider>
        </section>
      </div>
    </div>
  );
};

export { Banner };

